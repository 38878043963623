<template>
<div class="def-field-container">
    <el-row :gutter="8" class="row-container">
        <el-col :span="24">
            <el-button-group>
                <el-button type="success" round icon="el-icon-plus" @click="toAddRow">添加字段</el-button>
            </el-button-group>
        </el-col>
        <el-col :span="24">
            <!-- @cell-mouse-enter.once='rowDrop' -->
            <el-table
                v-loading="tableLoading"
                :data="n_fileList"
                fit
                highlight-current-row
                style="width: 100%;"
                :indent="24"
            >
                <el-table-column label="字段名称" width="160px" align="left">
                    <template slot-scope="{row}">
                        <el-input size="mini" v-model="row.fieldName" placeholder="字段名称"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="下载后上传" width="120px" align="center">
                    <template slot-scope="{row}">
                        <el-checkbox size="mini" :true-label="1" :false-label="0" v-model="row.download"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="是否必填" width="80px" align="center">
                    <template slot-scope="{row}">
                        <el-checkbox size="mini" :true-label="1" :false-label="0" v-model="row.required"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="模板" width="150px" align="center">
                    <template slot-scope="{row}">
                        <el-select v-show="row.download===1" size="mini" v-model="row.tmplId" filterable placeholder="选择模板">
                            <el-option value="">请选择</el-option>
                            <el-option v-for="item in exportTmplList" :value="item.tmplId" :label="item.tmplName" :key="item.tmplId"></el-option>
                        </el-select>

                    </template>
                </el-table-column>
                <el-table-column label="备注说明" align="center">
                    <template slot-scope="{ row }">
                        <el-input type="textarea" v-model="row.remarks" placeholder="备注说明"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="80px" fixed="right" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini"  icon="el-icon-delete"
                                   @click.native.prevent="deleteRow(scope.$index, n_fileList)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</div>
</template>

<script>
export default {
    props: {
        fileList: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            tableLoading: false,
            n_fileList: [],
            exportTmplList: [],
        }
    },
    watch: {
        fileList: {
            handler (val) {
                this.n_fileList = val;
                this.getTmpl()
            },
            deep: true,
            immediate: true
        },
        n_fileList: {
            handler (val) {
                this.$emit('update:fileList', val)
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        toAddRow: function () {
            this.n_fileList.push({
                fileId: '',
                fieldName: '',
                required: 1,
                remarks: '报名信息提交后，打开报名详情进行下载，可到【我的->我的报名】中查询到报名记录进行下载',
                download: 1
            })
        },
        deleteRow(index, rows) {
            this.$confirm('确定要删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                rows.splice(index, 1);
            }).catch(() => {
            });
        },
        getTmpl: function () {
            this.apis.get('course/enroll/export/tmpl/getList?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.exportTmplList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.def-field-container {
    width: 100%;
    height: 100%;
}
</style>
