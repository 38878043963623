<!-- 人脸识别 -->
<template>
    <div class="face">
        <div class="face-main">
            <div class="container" v-loading="cameraLoading" :element-loading-text="cameraLoadingTxt">
                <template v-show="openSuccess">
                    <video id="video" preload autoplay loop muted playsinline webkit-playsinline="true"></video>
                    <canvas id="canvas" width="300" height="300"></canvas>
                    <canvas id="canvas1" width="300" height="300"></canvas>
                </template>
                <div  v-show="!openSuccess" class="error-msg">
                    {{openMessage}}
                </div>
            </div>
            <div class="btns">
                <div v-show="openSuccess===false" class="btn" @click="initCamera">打开摄像头</div>
                <div v-show="openSuccess" class="btn" @click="screenshot">手动识别</div>
<!--                <div class="btn" @click="keepImg">保存图片</div>-->
            </div>
        </div>
        <div class="tips">
            <p class="tip">1、不支持IE浏览器，建议使用谷歌、火狐、Edge浏览器</p>
            <p>2、若浏览器弹出是否允许调用摄像头，请点击允许</p>
            <p>3、将正脸放在框中自动识别，也可点击手动识别</p>
            <div class="imgs" v-show="showCutImg">
                <p>认证人脸图像</p>
                <canvas id="shortCut" width="120" height="120"></canvas>
            </div>
        </div>
    </div>
</template>
<script>
import "../../assets/face/tracking-min.js";
import "../../assets/face/data/face-min.js";
export default {
    data() {
        return {
            cameraLoading: false,
            cameraLoadingTxt: '摄像头加载中',
            openSuccess: false,
            openMessage: '',
            video: null,
            saveArray: {},
            showCutImg: false,
            faceRecogLock: false // 锁
        };
    },
    destroyed() {},
    beforeDestroy() {
        this.closeFace();
    },
    methods: {
        init: function () {
            this.cameraLoading = true;
            this.cameraLoadingTxt = '正在初始化摄像头';
            setTimeout(() => {
                this.initCamera()
            }, 1000)
        },
        // 打开摄像头
        initCamera() {
            this.video = document.getElementById('video')
            const errocb = (e) => {
                console.log(e)
                this.$message.error('未找到摄像头或摄像头打开失败！')
                this.openMessage = '未找到摄像头或摄像头打开失败'
                this.openSuccess = false;
                this.cameraLoading = false;
            }
            if (navigator.webkitGetUserMedia) {
                navigator.webkitGetUserMedia({
                    audio: false,
                    video: true
                }, (stream) => {
                    // video.src=window.URL.createObjectURL(stream);
                    this.openSuccess = true;
                    this.video.srcObject = stream;
                    this.openCamera();
                    this.video.play();
                    this.cameraLoading = false;
                }, errocb)
            } else if (navigator.mediaDevices.getUserMedia) {
                // var constraints = { audio: true, video: { width: 1280, height: 720 } };
                const constraints = {
                    audio: false,
                    video: true
                }
                navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                    // var video = document.querySelector('video');
                    this.openSuccess = true;
                    this.video.srcObject = stream
                    this.video.onloadedmetadata = (e) => {
                        this.openCamera();
                        this.video.play();
                        this.cameraLoading = false;
                    }
                })
            } else if (navigator.getUserMedia) {
                navigator.getUserMedia({
                    audio: false,
                    video: true
                }, (stream) => {
                    this.openSuccess = true;
                    this.video.src = window.webkitURL.createObjectURL(stream)
                    this.video.srcObject = stream
                    this.openCamera();
                    this.video.play();
                    this.cameraLoading = false;
                }, errocb)
            } else {
                this.$message.error('你的浏览器不支持打开摄像头！')
                this.openMessage = '你的浏览器不支持打开摄像头'
            }
        },
        // 打开摄像头
        openCamera () {
            let that = this;
            this.cameraLoadingTxt = '正在加载摄像头'
            let saveArray = {};
            let canvas = document.getElementById("canvas");
            let context = canvas.getContext("2d");
            // eslint-disable-next-line no-undef
            let tracker = new window.tracking.ObjectTracker("face");
            tracker.setInitialScale(4);
            tracker.setStepSize(2);
            tracker.setEdgesDensity(0.1); // 转头角度影响识别率
            // eslint-disable-next-line no-undef
            this.trackerTask = window.tracking.track("#video", tracker);
            tracker.on("track", function(event) {
                if (event.data.length) {
                    //有头像有头像执行
                    //会一直拍照,所有这里加个锁
                    if (that.faceRecogLock === false) {
                        context.clearRect(0, 0, canvas.width, canvas.height);
                        event.data.forEach(function(rect) {
                            context.strokeStyle = "#fff";
                            context.strokeRect(rect.x, rect.y, rect.width, rect.height);
                            context.fillStyle = "#fff";
                            saveArray.x = rect.x;
                            saveArray.y = rect.y;
                            saveArray.width = rect.width;
                            saveArray.height = rect.height;
                        });
                    }
                }
            });
            let canvas1 = document.getElementById("canvas1");
            let context1 = canvas1.getContext("2d");
            context1.strokeStyle = "#69fff1"
            context1.moveTo(50, 50);
            context1.lineTo(250, 50);
            context1.lineTo(250, 250);
            context1.lineTo(50, 250);
            context1.lineTo(50, 50);
            // context1.moveTo(190, 118);
            // context1.lineTo(390, 118);
            // context1.lineTo(390, 318);
            // context1.lineTo(190, 318);
            // context1.lineTo(190, 118);
            context1.stroke();
            this.cameraLoading = false;
            setInterval(() => {
                if (this.faceRecogLock === false) {
                    if (saveArray.x > 50 &&
                        saveArray.x + saveArray.width < 300 &&
                        saveArray.y > 50 &&
                        saveArray.y + saveArray.height < 300 &&
                        saveArray.width < 200 &&
                        saveArray.height < 200
                        // saveArray.x > 200 &&
                        // saveArray.x + saveArray.width < 400 &&
                        // saveArray.y > 120 &&
                        // saveArray.y + saveArray.height < 320 &&
                        // saveArray.width < 180 &&
                        // saveArray.height < 180
                    ) {
                        console.log(saveArray);
                        this.getPhoto();
                        // eslint-disable-next-line guard-for-in
                        for (let key in saveArray) {
                            delete saveArray[key];
                        }
                    }
                }
            }, 2000);
        },
        // 获取人像照片
        getPhoto() {
            let video = document.getElementById("video");
            let vw = video.videoWidth
            let vh = video.videoHeight
            let xr = vw / 300;
            let yr = vh / 300;
            let can = document.getElementById("shortCut");
            let context2 = can.getContext("2d");
            // context2.drawImage(video, 210, 130, 210, 210, 0, 0, 140, 140);
            context2.drawImage(video, 50 * xr, 50 * yr, 200 * xr, 200 * xr, 0, 0, 140, 140);
            // context2.drawImage(video, 100, 100);
            this.showCutImg = true;
            this.faceRecogLock = true;
            let imgBase64 = can.toDataURL("image/png", 1); //1表示质量(无损压缩)
            this.$emit("callback", imgBase64)
        },
        retry() {
            this.showCutImg = false;
            this.faceRecogLock = false;
        },
        // 截屏
        screenshot() {
            this.getPhoto();
        },
        // 将canvas转化为图片
        convertCanvasToImage(canvas) {
            let image = new Image();
            image.src = canvas.toDataURL("image/png");
            return image;
        },
        //将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
        dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(",");
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        // 保存图片
        keepImg() {
            let can = document.getElementById("shortCut");
            let img = document.getElementById("img");
            let photoImg = document.createElement("img");
            photoImg.src = this.convertCanvasToImage(can).src;
            img.appendChild(photoImg);
            //获取到转化为base64的图片地址
            this.$emit(
                "canvasToImage",
                this.dataURLtoFile(this.convertCanvasToImage(can).src, "person.jpg")
            );

            console.log(
                this.dataURLtoFile(this.convertCanvasToImage(can).src, "person.jpg")
            );
        },
        clearCanvas() {
            let c = document.getElementById("canvas");
            let c1 = document.getElementById("canvas1");
            let cxt = c.getContext("2d");
            let cxt1 = c1.getContext("2d");
            cxt.clearRect(0, 0, 280, 280);
            cxt1.clearRect(0, 0, 280, 280);
        },
        closeFace() {
            console.log("关闭人脸识别窗口");
            this.showCutImg = false;
            this.clearCanvas();
            // 停止侦测
            this.trackerTask.stop();
            console.log(this.trackerTask);
            // 关闭摄像头
            // let video = document.getElementById("video");
            this.video.srcObject.getTracks()[0].stop();
        }
    }
};
</script>
<style scoped lang="scss">
.face {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .face-main {
        .container {
            background-clip: padding-box;
            opacity: 1;
            filter: blur(0px);
            border-image-source: url('../../assets/oes/images/border4.png');
            border-image-slice: 10 10 10 10 fill;
            border-width: 10px 10px 10px 10px;
            border-style: solid;
            box-sizing: border-box;
            //background: #000;
            //position: relative;
            flex: 0 0 300px;
            width: 300px;
            height: 300px;
            #canvas1 {
                position: absolute;
            }
            video,
            #canvas,
            #canvas1 {
                position: absolute;
                width: 280px;
                height: 280px;
            }
            .error-msg {
                text-align: center;
                vertical-align: center;
                line-height: 280px;
                font-size: 14px;
                color: red;
            }
        }
    }
    .tips {
        font-size: 14px;
        color: #666;
        margin: 10px 0;
        line-height: 24px;
        padding-left: 20px;
        box-sizing: border-box;
        .imgs {
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-items: center;
            p {
                font-size: 14px;
                color: red;
                text-align: center;
            }
            #shortCut {
                width: 120px;
                height: 120px;
            }
        }
    }
    .btns {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .btn {
        height: 40px;
        background: #1890ff;
        border-radius: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        padding: 0 20px;
        display: inline-block;
        white-space: nowrap;
    }
}
</style>
