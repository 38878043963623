<template>
    <div class="home">
        <el-dialog
            title="图片编辑"
            :visible.sync="showModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            @close="close"
            width="60%">
            <el-row :gutter="16" style="height: 600px; margin: 10px">
                <el-col style="height:100%">
                    <vueCropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.size"
                        :outputType="option.outputType"
                        :info="true"
                        :full="option.full"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :autoCrop="option.autoCrop"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :centerBox="option.centerBox"
                        :infoTrue="option.infoTrue"
                        :fixedBox="option.fixedBox"
                        @realTime="realTime"
                        @cropMoving="cropMoving"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :enlarge="option.enlarge"
                    ></vueCropper>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col :span="10">
                        <div class="inputWrap">
                            <span>宽：</span>
                            <div class="widthInput">
                                <el-input v-model="option.autoCropWidth" size="mini"></el-input>
                            </div>
                            <span>高：</span>
                            <div class="heightInput">
                                <el-input v-model="option.autoCropHeight" size="mini"></el-input>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <el-button v-for="(option, index) in scaleOption" type="text" :key="index" @click="setOptionWh(option)" v-show="option.name">{{option.name}}</el-button>
                        <el-button style="margin-left: 20px" type="text" @click="rotateLeft()">左旋转</el-button>
                        <el-button style="margin-left: 20px" type="text" @click="rotateRight()">右旋转</el-button>
                        <el-button style="margin-left: 20px" type="text" @click="close()">取消</el-button>
                        <el-button style="margin-left: 20px" type="primary" @click="upload()">保存</el-button>
                    </el-col>
                </el-row>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { VueCropper } from "vue-cropper";
    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            option: {
                type: Object,
                default() {
                    return {}
                }
            },
            scaleOption: {
                type: Array,
                default() {
                    return [
                        {
                            name: '',
                            width: 500,
                            height: 300
                        }
                    ]
                }
            }
        },
        components: {
            VueCropper,
        },
        watch: {
            show: {
                handler (val) {
                    // console.log('croper');
                    this.showModal = val;
                },
                deep: true,
                immediate: true
            },
            scaleOption: {
                handler (val) {
                    // console.log('croper2222');
                    if (val && val.length > 0) {
                        this.option.autoCropWidth = val[0].width;
                        this.option.autoCropHeight = val[0].height;
                    }
                },
                deep: true,
                immediate: true
            }
        },
        data () {
            return {
                showModal: false,
            };
        },
        mounted () {
            console.log('mount');
        },
        methods: {
            setOptionWh(soption){
                // this.$refs.cropper.cropW = soption.width;
                // this.$refs.cropper.cropH = soption.height;
                this.option.autoCropWidth = soption.width;
                this.option.autoCropHeight = soption.height;
            },
            // 放大/缩小
            changeScale (num) {
                let num2 = num || 1
                this.$refs.cropper.changeScale(num2)
            },
            // 坐旋转
            rotateLeft () {
                this.$refs.cropper.rotateLeft()
            },
            // 右旋转
            rotateRight () {
                this.$refs.cropper.rotateRight()
            },
            // 实时预览函数
            realTime (data) {
                // console.log(data)
                // console.log('cropW:' + this.$refs.cropper.cropW)
                // this.previews = data
                // this.option.autoCropWidth = data.w;
                // this.option.autoCropHeight = data.h;
            },
            cropMoving (data) {
                console.log('cropMoving')
                // this.previews = data
            },
            upload: function () {
                // this.$refs[this.option.refName].getCropBlob((data) => {
                this.$refs.cropper.getCropData((data) => {
                    // this.showSpin = true;
                    this.$emit("upload", data);
                });
                // this.$refs.cropper.getCropBlob((data) => {
                //     this.showSpin = true;
                //     this.$emit("upload", data);
                // });
            },
            close: function () {
                this.showModal = false;
                this.$emit("callback")
            },
            // handlerClose: function () {
            //     this.showModal = false;
            //     this.$emit("callback")
            // }
        }
    }
</script>
<style lang="scss" scoped>
    .inputWrap{
        display: flex;
        align-items: center;
        span{
            padding-left: 20px;
        }
        .widthInput{
            width: 80px;
        }
        .heightInput{
            width: 80px;
        }
    }
    .home {
        height: 100%;
        width: 100%;
        background-color: white;
        padding: 15px 10px;
        .cropper {
            width: auto;
            height: 300px;
        }
    }
    /deep/ .el-dialog{
        // height: 80vh;
        margin-top: 5vh!important;
    }
    /deep/ .el-dialog__body{
        // height: 500px!important;
        height: 70vh !important;
        padding: 0 10px;
    }
</style>
