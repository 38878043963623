<template>
    <el-dialog
        title="标记经纬度"
        :visible.sync="showMapDialog"
        :modal-append-to-body="true"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        width="80%"
        top="10vh"
        size="70%">
        <div class="map-demo">
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-input v-model="searchKeyword" placeholder="请输入地点关键词"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary">搜索</el-button>
                </el-col>
                <!-- <el-col :span="6">{{lng}}--{{lat}}</el-col> -->
            </el-row>
            <div class="map-wrapper">
                <baidu-map
                    class="bm-view"
                    ak="xUcPrHVKWbgYL6GHCTFfQXavoOd8P6Eq"
                    :center="center"
                    :zoom="zoom"
                    :scroll-wheel-zoom="true"
                    :double-click-zoom="false"
                    :pageCapacity = "10"
                    :panel="false"
                    @click="clickMap"
                    @ready="handleReady"
                >
                    <LocalSearch
                        :keyword="searchKeyword"
                        :auto-viewport="true"
                        :location="searchLocation"
                        @markersset='markersset'
                    ></LocalSearch>
                    <bm-marker
                        v-if="currentMarker"
                        :position="currentMarker"
                        :dragging="true"
                        :massClear="false"
                        @dragend="markerDragend"
                        @click="clickMarker">
                        <InfoWindow :show="showInfoWindow" @close="closeInfoWindow">{{infoWindowContent}}</InfoWindow>
                    </bm-marker>
                </baidu-map>
                <div class="searchResultList" v-show="searchResultList.length>0">
                    <div v-for="(item, index) in searchResultList" class="list-item" :key="index" @click="choosesearchResult(item)">
                        <div class="title">{{item.title}}</div>
                        <div class="address">{{item.address}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-button @click="showMapDialog = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import LocalSearch from "vue-baidu-map/components/search/LocalSearch.vue"; //搜索组件
import Marker from "vue-baidu-map/components/overlays/Marker.vue"; //点标记组件
import InfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue"; //信息窗体组件
export default {
    name: "MapDemo",
    components: {
        BaiduMap,
        LocalSearch,
        BmMarker: Marker,
        InfoWindow: InfoWindow,
    },
    props: {
        lat: {
            type: [String, Number],
            default: ''
        },
        lng: {
            type: [String, Number],
            default: ''
        },
    },
    data() {
        return {
            map: null, //地图实例
            BMap: null, //百度地图核心类，地图渲染完成后赋值
            center: { //地图中心
                lat: '',
                lng: ''
            },
            zoom: 4,
            searchKeyword: "", //搜索关键词
            searchLocation: "", //搜索限定区域
            searchResultList: [], //搜索结果
            currentMarker: null, //当前标记点坐标
            showInfoWindow: false,
            infoWindowContent: '', //信息窗体内容
            showMapDialog: false,
        };
    },
    watch: {
        // lat: {
        //     handler(val) {
        //         this.center.lat = val
        //         console.log(this.center.lat);
        //         if (!this.searchKeyword) {
        //             this.initMap(val, this.lng)
        //         }
        //     },
        // },
        // lng: {
        //     handler(val) {
        //         this.center.lng = val
        //         console.log(this.center.lng);
        //         if (!this.searchKeyword) {
        //             this.initMap(this.lat, val)
        //         }
        //     },
        // },
    },
    computed: {
        // center: function () {
        //     return {
        //         lat: this.lat,
        //         lng: this.lng
        //     }
        // }
    },
    methods: {
        handleReady(e) {
            //地图加载完成时执行
            console.log(e);
            this.BMap = e.BMap;
            this.map = e.map;
            // this.initMap(this.lat, this.lng)
        },
        //初始化地图数据
        initMap(lat, lng) {
            console.log("initMap");
            this.showMapDialog = true
            this.searchKeyword = ''
            if (lat&&lng) {
                this.$nextTick(() => {
                    this.center = {
                        lat: lat,
                        lng: lng
                    }
                    this.currentMarker = {
                        lng: lng,
                        lat: lat
                    }
                    console.log(this.currentMarker);
                })
            } else {
                this.$nextTick(() => {
                    this.toClearOverlays()
                    this.currentMarker = null
                    console.log(this.currentMarker);
                })
            }
        },
        clickMap(e) {
            if (!this.currentMarker) {
                console.log(e.point);
                this.currentMarker = {
                    lng: e.point.lng,
                    lat: e.point.lat
                }
                this.$emit('callback', e.point.lng, e.point.lat)
            }
            // console.log("经度：" + e.point.lng);
            // console.log("纬度：" + e.point.lat);
        },
        clickMarker(e) {
            console.log(e);
            this.toGetLocation(this.currentMarker.lng, this.currentMarker.lat)
            this.showInfoWindow = true
        },
        markerDragend(e) {
            this.$emit('callback', e.point.lng, e.point.lat)
            this.currentMarker = {
                lng: e.point.lng,
                lat: e.point.lat
            }
            this.toGetLocation(e.point.lng, e.point.lat)
        },
        //根据经纬度获取地址描述
        toGetLocation(lng, lat) {
            let myGeo = new this.BMap.Geocoder();
            myGeo.getLocation(new this.BMap.Point(lng, lat), (result) => {
                if (result){
                    console.log(result);
                    this.infoWindowContent = result.address || '无地址描述'
                }
            });
        },
        closeInfoWindow() {
            this.showInfoWindow = false
        },
        //搜索组件（LocalSearch）标注添加完成后的回调函数。
        markersset(p) {
            console.log('markersset参数');
            console.log(p);
            this.searchResultList = p
            this.toClearOverlays()
            this.currentMarker = {
                lng: p[0].point.lng,
                lat: p[0].point.lat
            }
        },
        //点击搜索结果列表后执行
        choosesearchResult(item) {
            this.currentMarker = {
                lng: item.point.lng,
                lat: item.point.lat
            }
            this.center= {
                lat: item.point.lat,
                lng: item.point.lng
            }
            this.zoom = 10
            this.infoWindowContent = item.address
        },
        save: function () {
            this.$emit('callback', this.currentMarker.lng, this.currentMarker.lat)
            this.showMapDialog = false
        },
        // 清除所有覆盖物
        toClearOverlays() {
            this.map.clearOverlays();
        },
    },
};
</script>

<style scoped lang="scss">
.map-wrapper {
    margin-top: 8px;
    display: flex;
    height: 50vh;
    overflow: hidden;
    .bm-view {
        flex-shrink: 0;
        width: 100%;
        height: 50vh;
        // display: flex;
        // overflow: hidden;
    }
    // .bm-view>div{
    //   width: 50%;
    //   overflow: auto;
    // }
    .searchResultList{
        position: absolute;
        right: 20px;
        width: 250px;
        //flex-shrink: 0;
        //flex-grow: 1;
        height: 50vh;
        overflow: auto;
        background-color: #00000030;
        .list-item{
            border-bottom: 1px solid #cccccc;
            cursor: pointer;
            padding: 5px;
            .title{
                color: #1890FF;
                line-height: 1.5;
            }
            .address{
                color: #666666;
                line-height: 1.5;
            }
        }
        .list-item:hover{
            background-color: #d5e5ff;
        }
    }
}
/deep/ .el-dialog__body{
    max-height: 80vh!important;
    padding: 10px 20px;
}
</style>
