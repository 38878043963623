var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"def-field-container"},[_c('el-row',{staticClass:"row-container",attrs:{"gutter":8}},[_c('el-col',{attrs:{"span":24}},[_c('el-button-group',[_c('el-button',{attrs:{"type":"success","round":"","icon":"el-icon-plus"},on:{"click":_vm.toAddRow}},[_vm._v("添加字段")])],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.n_fileList,"fit":"","highlight-current-row":"","indent":24}},[_c('el-table-column',{attrs:{"label":"字段名称","width":"160px","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"字段名称"},model:{value:(row.fieldName),callback:function ($$v) {_vm.$set(row, "fieldName", $$v)},expression:"row.fieldName"}})]}}])}),_c('el-table-column',{attrs:{"label":"下载后上传","width":"120px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"size":"mini","true-label":1,"false-label":0},model:{value:(row.download),callback:function ($$v) {_vm.$set(row, "download", $$v)},expression:"row.download"}})]}}])}),_c('el-table-column',{attrs:{"label":"是否必填","width":"80px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"size":"mini","true-label":1,"false-label":0},model:{value:(row.required),callback:function ($$v) {_vm.$set(row, "required", $$v)},expression:"row.required"}})]}}])}),_c('el-table-column',{attrs:{"label":"模板","width":"150px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{directives:[{name:"show",rawName:"v-show",value:(row.download===1),expression:"row.download===1"}],attrs:{"size":"mini","filterable":"","placeholder":"选择模板"},model:{value:(row.tmplId),callback:function ($$v) {_vm.$set(row, "tmplId", $$v)},expression:"row.tmplId"}},[_c('el-option',{attrs:{"value":""}},[_vm._v("请选择")]),_vm._l((_vm.exportTmplList),function(item){return _c('el-option',{key:item.tmplId,attrs:{"value":item.tmplId,"label":item.tmplName}})})],2)]}}])}),_c('el-table-column',{attrs:{"label":"备注说明","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"textarea","placeholder":"备注说明"},model:{value:(row.remarks),callback:function ($$v) {_vm.$set(row, "remarks", $$v)},expression:"row.remarks"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"80px","fixed":"right","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"mini","icon":"el-icon-delete"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRow(scope.$index, _vm.n_fileList)}}},[_vm._v("删除")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }