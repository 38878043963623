<template>
    <div>
        <el-row :gutter="16" type="flex" justify="space-between" class="head-query">
            <el-col :span="12" >
                <el-row class="query-container">
                    <el-col :span="24">
                        <el-form :inline="true">
                            <el-form-item label="">
                                <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable
                                          style="width:120px"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" @click="initTable">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="success" round icon="md-add" @click="toAdd">新增</el-button>
            </el-col>
        </el-row>
        <el-row :gutter="16">
            <el-col :span="24">
                <el-table
                    v-loading="tableLoading"
                    :data="tableData"
                    fit
                    stripe
                    highlight-current-row
                    style="width: 100%;"
                    ref="queryTable"
                    :indent="20"
                >
                    <el-table-column
                        label="#"
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column label="姓名" prop="trueName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row}">
                            <el-button v-if="row.deleteStatus===0" type="danger" plain size="mini"  icon="el-icon-delete" @click="toDelete(row.managerId, 1)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-dialog
            title="选择班级负责人"
            :visible.sync="selelctModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="700px">
            <div>
                <el-row :gutter="16">
                    <el-col :span="24">
                        <el-row class="query-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="mgrQueryParams.keyword" placeholder="查询关键字" clearable
                                                  style="width:120px"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <el-table
                            v-loading="tableLoading"
                            :data="managerData"
                            fit
                            stripe
                            highlight-current-row
                            style="width: 100%;"
                            ref="queryTable"
                            :indent="20"
                        >
                            <el-table-column
                                label="NO."
                                type="index"
                                width="50">
                            </el-table-column>
                            <el-table-column label="姓名" prop="trueName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                                <template slot-scope="{row}">
                                    <el-button plain size="mini" @click="toselect(row.userId)">选择</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <Pagination v-show="mgrQueryParams.totalRecord>0" :total="mgrQueryParams.totalRecord"
                                    :page.sync="mgrQueryParams.pageNumber" :limit.sync="mgrQueryParams.pageSize"
                                    @pagination="query"/>
                    </el-col>
                </el-row>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="selelctModal = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination';
    export default {
        components: {
            Pagination
        },
        data () {
            return {
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID(),
                },
                tableData: [],
                managerData: [],
                tableLoading: false,
                selelctModal: false,
                classId: '',
                orgId: '',
                mgrQueryParams: {
                    pageNumber: 0,
                    pageSize: 5,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID(),
                }
            };
        },
        methods: {
            initData: function (classId, orgId) {
                this.classId = classId;
                this.orgId = orgId;
                this.initTable()
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.get('course/class/getManagerList?classId=' + this.classId + '&orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            toAdd: function () {
                this.selelctModal = true;
                this.query();
            },
            refreshTable: function () {
                this.mgrQueryParams.pageNumber = 0;
                this.query()
            },
            query: function () {
                this.apis.postForm('system/admin/queryCustom', this.mgrQueryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.managerData = res.data
                            this.mgrQueryParams.totalRecord = res.count;
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        managerId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/class/deleteManager', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toselect: function (id) {
                let url = "course/class/saveManager";
                let info = {};
                info.classId = this.classId;
                info.userId = id;
                info.createBy = this.appCookies.getCookieUID();
                info.createByname = this.appCookies.getCookieUNAME() ;
                info.modifyBy = this.appCookies.getCookieUID();
                info.modifyByname = this.appCookies.getCookieUNAME() ;
                // 保存数据
                this.apis.postJson(url, info)
                    .then((res) => {
                        if (res.code === 1) {
                            this.selelctModal = false;
                            this.$message({
                                message: '保存成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    // .home {
    //     height: 100%;
    //     width: 100%;
    //     background-color: white;
    //     padding: 15px 10px;
    // }
    // .head-query .ivu-form-item {
    //     margin-bottom: 5px;
    // }
</style>
