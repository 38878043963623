<template>
    <el-dialog
        title="课程选择"
        :visible.sync="courseDialog"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :fullscreen="true"
        :modal="false"
        width="100%">
        <el-row :gutter="8" class="row-container">
            <!-- 工种分类 -->
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="treeSelectChange"></GnvqTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>课程列表</span>
                        <div style="float: right; padding: 0px 0">
                            <!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
                            <!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button type="success" round icon="el-icon-plus" @click="mutiSelect">确认选择
                                    </el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-el-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                @selection-change="handleSelectionChange"
                                :indent="20"
                            >
                                <el-table-column
                                    type="selection"
                                    width="50">
                                </el-table-column>
                                <el-table-column label="课程名称" prop="courseName" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="课程时长" prop="courseTime" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="课程图片" prop="courseImgUrl" min-width="80px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row}">
                                        <img :src="row.courseImgUrl" style="width: 50px;border-radius: 2px;">
                                    </template>
                                </el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="价格(元)" prop="baseCatName" min-width="150px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.salePrice + '/' + row.coursePrice}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="有效期" prop="periodDays" min-width="100px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.periodDays + '天'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="发布时间" prop="publishTime" min-width="180px" align="center" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="80" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button type="text" size="mini" @click="singleSelect(row)">选择 </el-button>
                                    </template>
                                </el-table-column>

                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="courseDialog = false">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination';
import GnvqTree from "@v/components/GnvqTree";
export default {
    components: {
        Pagination,
        GnvqTree,
    },
    data () {
        return {
            queryParams: {
                pageNumber: 0,
                pageSize: 12,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                deleteStatus: 0,
                publishStatus: 1,
                recommend: '',
                gnvqCatId: '',
                gnvqId: '',
                levelId: '',
                orgId: this.appCookies.getCookieTOID()
            },
            tableData: [],
            tableLoading: false,
            courseDialog: false,
            selectData: [],
        };
    },
    mounted () {
    },
    methods: {
        open: function (gnvqCatId, gnvqId, levelId) {
            this.courseDialog = true;
            this.queryParams.gnvqCatId = gnvqCatId;
            this.queryParams.gnvqId = gnvqId;
            this.queryParams.levelId = levelId;
            this.refreshTable();
        },
        treeSelectChange: function (data) {
            this.cellIndex = '-1';
            this.queryParams.gnvqCatId = '';
            this.queryParams.gnvqId = '';
            this.queryParams.levelId = '';
            if (data.type === 'CAT') {
                this.queryParams.gnvqCatId = data.id;
            } else if (data.type === 'INFO') {
                this.queryParams.gnvqCatId = data.categoryId;
                this.queryParams.gnvqId = data.id;
            } else {
                this.queryParams.gnvqCatId = data.categoryId;
                this.queryParams.gnvqId = data.parentId;
                this.queryParams.levelId = data.id;
            }
            this.refreshTable()
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('course/info/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        singleSelect: function (row) {

            let courseList = [];
            courseList.push({
                courseId: row.courseId,
                courseName: row.courseName,
                courseImgUrl: row.courseImgUrl,
                courseTime: row.courseTime
            })
            this.$emit("callback", courseList)
            this.courseDialog = false;
        },
        //获取表格被选中列的id
        mutiSelect: function () {
            if (this.selectData.length === 0) {
                this.$message({
                    message: '未选择任何记录，请选择',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            let courseList = [];
            this.selectData.forEach(function (item) {
                courseList.push({
                    courseId: item.courseId,
                    courseName: item.courseName,
                    courseImgUrl: item.courseImgUrl,
                    courseTime: item.courseTime
                })
            });
            this.$emit("callback", courseList)
            this.courseDialog = false;
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
