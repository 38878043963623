<template>
    <el-dialog
        title="小程序分享码"
        :visible.sync="miniShareDialog"
        :destroy-on-close="false"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="12" style="text-align: center">
                <div class="share-img">
                    <img style="width: 200px;  border-radius: 10px;" :src="shareInfo.shareImgUrl">
                </div>
            </el-col>
            <el-col :span="12">
                <div class="img-list">
                    <div class="img-box" v-for="(item, idx) in certTmplList" :key="idx" >
                        <img style="width: 100%; height: 100%; border-radius: 10px;"
                             :class="{'active-tab' : item.certTmplId === certTmplId}"
                             :src="item.certTmplImgUrl" @click="genShareImg(item.certTmplId)">
                    </div>
                </div>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="miniShareDialog = false">关 闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            miniShareDialog: false,
            certTmplList: [],
            classId: '',
            orgName: '',
            shareInfo: {},
            certTmplId: ''
        }
    },
    mounted() {
    },
    methods: {
        open: function (classId, orgName) {
            this.miniShareDialog = true;
            this.getShareTmpl();
            this.classId = classId;
            this.orgName = orgName
        },
        getShareTmpl: function () {
            this.apis.get('system/certtmpl/getList?useStatus=1&certTmplType=SHARE&orgId=' + this.appCookies.getCookieTOID())
                .then((res) => {
                    if (res.code === 1) {
                        this.certTmplList = res.data || [];
                        if (this.certTmplList.length > 0) {
                            this.certTmplId = this.certTmplList[0].certTmplId;
                            this.genShareImg(this.certTmplId)
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        genShareImg: function (certTmplId) {
            this.mainLoading = true;
            this.certTmplId = certTmplId;
            for (let i = 0; i < this.certTmplList.length; i++) {
                if (this.certTmplList[i].certTmplId === this.certTmplId) {
                    if (this.certTmplList[i].shareImgUrl) {
                        this.shareInfo.shareImgUrl = this.certTmplList[i].shareImgUrl;
                        this.mainLoading = false
                        return;
                    }
                }
            }

            let data = {
                userId: "SYSTEM",
                classId: this.classId,
                certTmplId: certTmplId,
                avatarUrl: '',
                nickName: this.orgName
            }
            this.apis.postForm('user/class/share/img/getShareImg', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.shareInfo = res.data || {}
                        for (let i = 0; i < this.certTmplList.length; i++) {
                            if (this.certTmplList[i].certTmplId === this.certTmplId) {
                                this.certTmplList[i].shareImgUrl = this.shareInfo.shareImgUrl
                            }
                        }

                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                    this.mainLoading = false
                }).catch((err) => {
                this.mainLoading = false
                console.log(err)
            });
        }
    }
}
</script>

<style scoped lang="scss">
    .img-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0;
        .img-box {
            width: 150px;
            height: 250px;
            padding: 20px 0;
            cursor: pointer;
        }
    }
</style>
