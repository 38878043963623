var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"def-field-container"},[_c('el-row',{staticClass:"row-container",attrs:{"gutter":8}},[_c('el-col',{attrs:{"span":24}},[_c('el-button-group',[_c('el-button',{attrs:{"type":"success","round":"","icon":"el-icon-plus"},on:{"click":_vm.toAddRow}},[_vm._v("添加字段")])],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.n_filedList,"fit":"","highlight-current-row":"","indent":24}},[_c('el-table-column',{attrs:{"label":"字段名称","width":"140px","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"字段名称"},model:{value:(row.fieldName),callback:function ($$v) {_vm.$set(row, "fieldName", $$v)},expression:"row.fieldName"}})]}}])}),_c('el-table-column',{attrs:{"label":"类型","width":"120px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"size":"mini","placeholder":"选择类型"},model:{value:(row.controlType),callback:function ($$v) {_vm.$set(row, "controlType", $$v)},expression:"row.controlType"}},[_c('el-option',{key:"",attrs:{"label":"请选择","value":""}}),_vm._l((_vm.controlTypes),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})})],2)]}}])}),_c('el-table-column',{attrs:{"label":"是否必填","width":"80px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"size":"mini","true-label":1,"false-label":0},model:{value:(row.required),callback:function ($$v) {_vm.$set(row, "required", $$v)},expression:"row.required"}})]}}])}),_c('el-table-column',{attrs:{"label":"排序号","width":"120px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"排序号"},model:{value:(row.orderNum),callback:function ($$v) {_vm.$set(row, "orderNum", $$v)},expression:"row.orderNum"}})]}}])}),_c('el-table-column',{attrs:{"label":"备注说明","width":"180px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"备注说明"},model:{value:(row.remarks),callback:function ($$v) {_vm.$set(row, "remarks", $$v)},expression:"row.remarks"}})]}}])}),_c('el-table-column',{attrs:{"label":"选项（单选/多选）","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.controlType==='CHECKBOX' || row.controlType==='RADIO' || row.controlType==='SELECT')?[_c('el-row',{attrs:{"gutter":8}},[_c('el-col',{attrs:{"span":4,"offset":4}},[_c('span',[_vm._v("选项值(1/2/3/4)")])]),_c('el-col',{attrs:{"span":10}},[_c('span',[_vm._v("选项标签")])])],1),_vm._l((row.controlOptions),function(item,index){return _c('el-row',{key:index,staticStyle:{"margin":"5px 0"},attrs:{"gutter":8}},[_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":4}},[_c('span',[_vm._v("选项"+_vm._s(index+1)+":")])]),_c('el-col',{attrs:{"span":4}},[_c('el-input',{attrs:{"type":"text","size":"mini","placeholder":"选项值"},model:{value:(item.optionValue),callback:function ($$v) {_vm.$set(item, "optionValue", $$v)},expression:"item.optionValue"}})],1),_c('el-col',{attrs:{"span":10}},[_c('el-input',{attrs:{"type":"text","size":"mini","placeholder":"选项名称"},model:{value:(item.optionLable),callback:function ($$v) {_vm.$set(item, "optionLable", $$v)},expression:"item.optionLable"}})],1),_c('el-col',{attrs:{"span":3}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.optionsRemove(row, index)}}},[_vm._v("删除")])],1)],1)}),_c('el-row',{attrs:{"gutter":16}},[_c('el-col',{attrs:{"span":14,"offset":4}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"plain":"","size":"mini","icon":"el-icon-plus"},on:{"click":function($event){return _vm.optionsAdd(row)}}},[_vm._v("添加选项")])],1)],1)]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"80px","fixed":"right","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"mini","icon":"el-icon-delete"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRow(scope.$index, _vm.n_filedList)}}},[_vm._v("删除")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }