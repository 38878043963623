<template>
<div class="def-field-container">
    <el-row :gutter="8" class="row-container">
        <el-col :span="24">
            <el-button-group>
                <el-button type="success" round icon="el-icon-plus" @click="toAddRow">添加字段</el-button>
            </el-button-group>
        </el-col>
        <el-col :span="24">
            <!-- @cell-mouse-enter.once='rowDrop' -->
            <el-table
                v-loading="tableLoading"
                :data="n_filedList"
                fit
                highlight-current-row
                style="width: 100%;"
                :indent="24"
            >
                <el-table-column label="字段名称" width="140px" align="left">
                    <template slot-scope="{row}">
                        <el-input size="mini" v-model="row.fieldName" placeholder="字段名称"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="类型" width="120px" align="center">
                    <template slot-scope="{row}">
                        <el-select size="mini" v-model="row.controlType" placeholder="选择类型">
                            <el-option key="" label="请选择" value=""></el-option>
                            <el-option
                                v-for="item in controlTypes"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="是否必填" width="80px" align="center">
                    <template slot-scope="{row}">
                        <el-checkbox size="mini" :true-label="1" :false-label="0" v-model="row.required"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="排序号" width="120px" align="center">
                    <template slot-scope="{ row }">
                        <el-input size="mini" v-model="row.orderNum" placeholder="排序号"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="备注说明" width="180px" align="center">
                    <template slot-scope="{ row }">
                        <el-input v-model="row.remarks" placeholder="备注说明"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="选项（单选/多选）" align="center">
                    <template slot-scope="{ row }">
                        <template v-if="row.controlType==='CHECKBOX' || row.controlType==='RADIO' || row.controlType==='SELECT'">
                            <el-row :gutter="8">
                                <el-col :span="4" :offset="4">
                                    <span>选项值(1/2/3/4)</span>
                                </el-col>
                                <el-col :span="10">
                                    <span>选项标签</span>
                                </el-col>
                            </el-row>
                            <el-row :gutter="8" v-for="(item, index) in row.controlOptions" :key="index" style="margin: 5px 0">
                                <el-col :span="4" style="text-align: right">
                                    <span>选项{{index+1}}:</span>
                                </el-col>
                                <el-col :span="4">
                                    <el-input type="text" size="mini" v-model="item.optionValue" placeholder="选项值"></el-input>
                                </el-col>
                                <el-col :span="10">
                                    <el-input type="text" size="mini" v-model="item.optionLable" placeholder="选项名称"></el-input>
                                </el-col>
                                <el-col :span="3">
                                    <el-button size="mini" @click="optionsRemove(row, index)">删除</el-button>
                                </el-col>
                            </el-row>
                            <el-row :gutter="16" >
                                <el-col :span="14" :offset="4">
                                    <el-button plain @click="optionsAdd(row)" size="mini" icon="el-icon-plus" style="width:100%">添加选项</el-button>
                                </el-col>
                            </el-row>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="80px" fixed="right" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini"  icon="el-icon-delete"
                                   @click.native.prevent="deleteRow(scope.$index, n_filedList)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</div>
</template>

<script>
export default {
    props: {
        filedList: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            tableLoading: false,
            n_filedList: [],
            controlTypes: [ // 组件类型 INPUT, CHECKBOX , RADIO, TEXTAREA
                { code: 'INPUT', name: '文本框' },
                { code: 'CHECKBOX', name: '多选框' },
                { code: 'RADIO', name: '单选框' },
                { code: 'SELECT', name: '下拉选择' },
                { code: 'TEXTAREA', name: '大文本' },
                { code: 'DATE', name: '日期' },
                { code: 'DATERANGE', name: '日期区间' },
            ]
        }
    },
    watch: {
        filedList: {
            handler (val) {
                this.n_filedList = val
            },
            deep: true,
            immediate: true
        },
        n_filedList: {
            handler (val) {
                this.$emit('update:filedList', val)
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        toAddRow: function () {
            this.n_filedList.push({
                fieldId: '',
                fieldName: '',
                controlType: 'INPUT',
                required: 0,
                remarks: '',
                controlOption: '',
                controlOptions: [
                    {
                        optionValue: '',
                        optionLable: '',
                    }
                ]
            })
        },
        deleteRow(index, rows) {
            this.$confirm('确定要删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                rows.splice(index, 1);
            }).catch(() => {
            });
        },
        //添加单选题选项
        optionsAdd: function (item) {
            if (item.controlOptions === undefined || item.controlOptions === null || item.controlOptions === '') {
                item.controlOptions = []
            }
            item.controlOptions.push({ optionValue: '', optionLable: '', });
        },
        //删除单选题选项
        optionsRemove: function (item, index) {
            this.$confirm('确定要删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                item.controlOptions.splice(index, 1);
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.def-field-container {
    width: 100%;
    height: 100%;
}
</style>
