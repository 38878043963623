<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 工种分类 -->
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>班级列表</span>
                        <div style="float: right; padding: 0px 0">
                            <el-button-group>
                                <el-button size="mini" type="success" round icon="el-icon-plus" @click="toAdd">新增
                                </el-button>
                            </el-button-group>
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true" label-suffix=":">
                                <el-form-item label="关键字">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable
                                              style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item  label="机构" v-if="this.privRange.havePriv()">
                                    <el-select v-model="queryParams.companyId" placeholder="请选择培训机构" filterable style="width:150px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option v-for="item in orgList" :label="item.orgName" :value="item.orgId"
                                                   :key="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="类型">
                                    <el-select filterable v-model="queryParams.classType" placeholder="开班类型" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="考试报名" value="ENROLL"></el-option>
                                        <el-option label="继续教育" value="LEARN"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="年份">
                                    <el-input v-model="queryParams.yearNum" placeholder="查询年份" clearable
                                              style="width:100px"></el-input>
                                </el-form-item>
                                <el-form-item label="第几期">
                                    <el-input v-model="queryParams.classNum" placeholder="第几期" clearable
                                              style="width:100px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button type="text"  @click="showMore=!showMore">更多条件</el-button>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row class="query-container" v-show="showMore">
                        <el-col :span="24">
                            <el-form :inline="true" label-suffix=":">
                                <el-form-item label="发布状态">
                                    <el-select filterable v-model="queryParams.confirmStatus" placeholder="发布状态"
                                               style="width:90px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="未发布" value="0"></el-option>
                                        <el-option label="已发布" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="删除状态">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="删除状态" style="width:90px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="首页轮播">
                                    <el-select v-model="queryParams.bannerStatus" placeholder="是否轮播" style="width:90px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="否" value="0"></el-option>
                                        <el-option label="是" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="结束状态">
                                    <el-select v-model="queryParams.dataStatus" placeholder="是否结束" style="width:90px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="已结束" value="1"></el-option>
                                        <el-option label="进行中" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                :show-header="false"
                                fit
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column>
                                    <template slot-scope="{row}">
                                        <div class="table-row">
                                            <div class="left">
                                                <img class="img" :src="row.classImgUrl"/>
                                            </div>

                                            <div class="center">
                                                <div class="header">
                                                    <div class="item">
                                                    <span class="label">{{ row.gnvqName }}</span>
                                                        <span class="value">{{ row.levelName }}</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">培训机构：</span>
                                                            <span class="value">{{ row.companyName }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">班级类型：</span>
                                                            <span class="value">{{
                                                                    row.classType  | classTypeFilter
                                                                }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">第几期：</span>
                                                            <span class="value">{{ row.yearNum }}-{{
                                                                    row.classNum
                                                                }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">报名费用：</span>
                                                            <span class="value">{{ row.amount }}元</span>
                                                        </div>
                                                    </div>
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">报名截止：</span>
                                                            <span class="value">{{ row.endDate }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">学习截止：</span>
                                                            <span class="value">{{ row.studyEndDate }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">结束状态：</span>
                                                            <span class="value">
                                                                <el-tag :type="row.dataStatus | colorFilter" size="mini">{{row.dataStatus  | dataStatusFilter}}</el-tag>
                                                            </span>
                                                        </div>

                                                        <div class="item">
                                                            <span class="label">可分享：</span>
                                                            <span class="value">
                                                                <el-tag :type="row.shareSign | colorFilter2" size="mini">
                                                                    {{row.shareSign  | shareSignFilter}}
                                                                </el-tag>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">发布状态：</span>
                                                            <span class="value">
                                                                <el-switch v-model="row.confirmStatus"
                                                                           :active-value="1"
                                                                           :inactive-value="0"
                                                                           active-color="#2d8cf0"
                                                                           inactive-color="#efefef"
                                                                           @change="toLock(row)">
                                                                </el-switch>
                                                            </span>
                                                        </div>

                                                        <div class="item">
                                                            <span class="label">首页轮播：</span>
                                                            <span class="value">
                                                                <el-switch
                                                                    v-model="row.bannerStatus"
                                                                    :active-value="1"
                                                                    :inactive-value="0"
                                                                    active-color="#2d8cf0"
                                                                    inactive-color="#efefef"
                                                                    @change="toBanner(row)">
                                                               </el-switch>
                                                             </span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">状态：</span>
                                                            <span class="value">
                                                                <el-tag :type="row.deleteStatus | colorFilter" size="mini">{{row.deleteStatus  | deleteStatusFilter}}</el-tag>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
<!--                                                <div class="footer">-->
<!--                                                </div>-->
                                            </div>


                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="80" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <div class="oper-col">
                                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="toEdit(row.classId)">修改
                                            </el-button>
                                            <el-dropdown size="mini" @command="dropdownOperate">
                                                                                    <span class="el-dropdown-link">
                                                                                        更多<i
                                                                                        class="el-icon-arrow-down el-icon--right"></i>
                                                                                      </span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item v-if="row.shareSign === 1"
                                                                      :command="dropdownValue('review', row)">分享预览
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.dataStatus === 0 && row.deleteStatus === 0"
                                                                      :command="dropdownValue('finish', row)">结束报名
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.dataStatus === 1 && row.deleteStatus === 0"
                                                                      :command="dropdownValue('unfinish', row)">取消结束报名
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.deleteStatus === 0"
                                                                      :command="dropdownValue('manager', row)">班级负责人
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.deleteStatus === 0"
                                                                      :command="dropdownValue('classExam', row)">考试安排
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.deleteStatus === 0"
                                                                      :command="dropdownValue('cert', row)">结业证书
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.deleteStatus === 0 && row.confirmStatus === 1" :command="dropdownValue('miniShare', row)">小程序分享码
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.deleteStatus === 0 && row.confirmStatus === 1">
                                                        <el-popover placement="bottom"
                                                                    width="400"
                                                                    trigger="click" @show="getSignQrUrl(row.classId)">
                                                            <div style="width: 100%; height: 300px; padding: 30px 18px; display: flex; flex-direction: column; justify-content: space-between; align-items: center">
                                                                <img :src="signQrUrl" alt="" style="width: 180px; height: 180px;">
                                                                <p style="font-size: 13px; color: #666666">截屏二维码，发送给学员，微信识别签名</p>
                                                                <p style="font-size: 13px; color: red">识别后，需登录小程序后进行签名</p>
                                                            </div>
                                                            <div slot="reference">报名签名码</div>
                                                        </el-popover>
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.dataStatus === 0 && row.deleteStatus === 0"
                                                                      :command="dropdownValue('delete', row)" divided>删除
                                                    </el-dropdown-item>
                                                    <el-dropdown-item v-if="row.dataStatus === 0 && row.deleteStatus === 1"
                                                                      :command="dropdownValue('recover', row)" divided>恢复
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                            <!-- 列表内容 -->
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 班级管理侧边栏 -->
        <el-drawer
            title="班级管理"
            :visible.sync="openDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content input-collapse">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item title="基本信息" name="c1">
                            <template slot="title">
                                <span class="title">基本信息</span>
                            </template>
                            <el-row>
                                <el-col :span="8" v-if="this.privRange.havePriv()">
                                    <el-form-item label="培训机构" prop="orgId" @change="onOrgChange">
                                        <el-select filterable v-model="info.companyId">
                                            <el-option value="">请选择</el-option>
                                            <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"
                                                       :key="item.orgId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="所属培训计划" prop="trainId">
                                        <el-select v-model="info.trainId">
                                            <el-option :value="0" label="默认"></el-option>
                                            <el-option v-for="item in trainPlanList" :value="item.trainId" :label="item.trainName"
                                                       :key="item.trainId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="16">
                                    <el-form-item label="工种分类" prop="gnvqCatId">
                                        <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                                    :levelId.sync="info.levelId"></GnvqSelect>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="8">
                                    <el-form-item label="班级类型" prop="classType">
                                        <span slot="label">
                                            班级类型
                                            <el-tooltip effect="dark" content="区分是考试报名还是企业内部培训" placement="top">
                                              <i class="el-icon-question"/>
                                            </el-tooltip>
                                            :
                                       </span>
                                        <el-radio-group v-model="info.classType">
                                            <el-radio label="ENROLL">考试报名</el-radio>
                                            <el-radio label="LEARN">继续教育</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="16">
                                    <el-form-item label="报名方式" prop="enrollType">
                                        <span slot="label">
                                            报名方式
                                            <el-tooltip effect="dark" content="公开报名：对所有人开放报名；内部报名：只能通过邀请码进行报名；自动加入：主要针对企业内部培训" placement="top">
                                              <i class="el-icon-question"/>
                                            </el-tooltip>
                                            :
                                       </span>
                                        <el-radio-group v-model="info.enrollType">
                                            <el-radio :label="1">公开报名</el-radio>
                                            <el-radio :label="2">内部报名</el-radio>
                                            <el-radio :label="3">自动加入（无需报名）</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
<!--                                <el-col :span="8">-->
<!--                                    <el-form-item label="是否填写银行卡" prop="needBankno">-->
<!--                                        <el-radio-group v-model="info.needBankno">-->
<!--                                            <el-radio :label="0">否</el-radio>-->
<!--                                            <el-radio :label="1">是</el-radio>-->
<!--                                        </el-radio-group>-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="8">
                                    <el-form-item label="年份" prop="yearNum">
                                        <el-input v-model="info.yearNum" placeholder="请输入4位年份"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="第几期" prop="classNum">
                                        <el-input v-model="info.classNum" placeholder="请输入第几期"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="学习结束时间" prop="studyEndDate">
                                        <el-date-picker
                                            v-model="info.studyEndDate"
                                            type="date"
                                            placeholder="选择日期"
                                            @change="info.studyEndDate=$event"
                                            value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="8">
                                    <el-form-item label="报名金额" prop="amount">
                                        <el-input v-model="info.amount" placeholder="请输入报名金额"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="报名结束时间" prop="endDate">
                                        <el-date-picker
                                            v-model="info.endDate"
                                            type="date"
                                            placeholder="选择日期"
                                            @change="info.endDate=$event"
                                            value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="8">
                                    <el-form-item label="班级图片">
                                        <SingleUploadImg @callback="imageCallback" :imageUrl="info.classImgUrl"
                                                         :width="120" :height="60"></SingleUploadImg>
                                        <el-button type="success" size="mini" @click="toSelectClassImage">选择班级图片</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="首页轮播图" prop="bannerStatus">
                                        <el-radio-group v-model="info.bannerStatus">
                                            <el-radio :label="1">是</el-radio>
                                            <el-radio :label="0">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item v-show="info.bannerStatus===1" label="首页轮播图图片">
                                        <SingleUploadImg @callback="bannerImgCallback" :imageUrl="info.bannerImgUrl"
                                                         :width="192" :height="60"></SingleUploadImg>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item title="班级信息" name="c2">
                            <template slot="title">
                                <span class="title">班级信息</span>
                            </template>
                            <el-tabs value="m1" type="card">
                                <el-tab-pane label="班级课程" name="m1">
                                    <el-row :gutter="32">
                                        <el-col :span="24">
                                            <el-form-item label="是否可看视频" prop="canCourse">
                                                <el-radio-group v-model="info.canCourse">
                                                    <el-radio :label="0">否</el-radio>
                                                    <el-radio :label="1">是</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" v-show="info.canCourse===1">
                                            <el-form-item label="选择班级课程">
                                                <div class="course-list">
                                                    <el-card v-for="(item, cindex) in info.courseList" :key="cindex"
                                                             :body-style="{padding: '0px', width: '200px' }">
                                                        <el-image fit="fill" lazy class="image" :src="item.courseImgUrl">
                                                            <div slot="placeholder" class="image-slot">
                                                                加载中<span class="dot">...</span>
                                                            </div>
                                                        </el-image>
                                                        <div style="padding: 0 14px;">
                                                            <span>{{item.courseName}}</span>
                                                            <div class="clearfix" style="line-height: 20px;">
                                                                <span style="font-size: 13px; color: #999;">{{item.courseTime}}分钟</span>
                                                                <el-button type="text" @click="deleteCourse(cindex)" style="padding: 0px; float: right;">删除</el-button>
                                                            </div>
                                                        </div>
                                                    </el-card>
                                                    <div class="course-add" @click="toSelectCourse">
                                                        <i class="el-icon-plus icon"></i>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="考试刷题" name="m2">
                                    <el-row :gutter="32">
                                        <el-col :span="12">
                                            <el-form-item label="是否可刷题" prop="canExercise">
                                                <el-radio-group v-model="info.canExercise">
                                                    <el-radio :label="0">否</el-radio>
                                                    <el-radio :label="1">是</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="是否可刷专项练习题库" prop="canExerciseFreq">
                                                <el-radio-group v-model="info.canExerciseFreq">
                                                    <el-radio :label="0">否</el-radio>
                                                    <el-radio :label="1">是</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>

<!--                                        <el-col :span="12">-->
<!--                                            <el-form-item v-show="info.canExercise" label="刷题范围" prop="exerciseGroupId">-->
<!--                                                <GroupEditorSelect ref="groupSelect"-->
<!--                                                                   apiPrefix="/course/examItem/group"-->
<!--                                                                   :editable="false"-->
<!--                                                                   :groupId.sync="info.exerciseGroupId"-->
<!--                                                                   :gnvqId="info.gnvqId"-->
<!--                                                                   :levelId="info.levelId">-->
<!--                                                </GroupEditorSelect>-->
<!--                                            </el-form-item>-->
<!--                                        </el-col>-->
                                    </el-row>
                                    <el-row :gutter="32">
                                        <el-col :span="6">
                                            <el-form-item label="是否可模拟考试" prop="canExam">
                                                <el-radio-group v-model="info.canExam">
                                                    <el-radio :label="0">否</el-radio>
                                                    <el-radio :label="1">是</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="10" v-if="info.canExam===1 || info.canExercise===1">
                                            <el-form-item label="刷题/模拟考试规则" prop="examRuleId">
                                                <el-input v-model="info.examRuleName" :readonly="true">
                                                    <el-button @click="toSelectExamRule" slot="append" icon="el-icon-search"></el-button>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" v-if="info.canExam===1">
                                            <el-form-item label="模拟考试认证方式" prop="canExam">
                                                <el-radio-group v-model="info.examLoginWay">
                                                    <el-radio :label="0">不认证</el-radio>
                                                    <el-radio :label="1">人脸识别</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="32">
                                        <el-col :span="24">
                                            <el-form-item label="试卷导出标题" prop="examTitle">
                                                <el-input v-model="info.examTitle" placeholder="请输入试卷标题"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="学习设置" name="m3">
                                    <el-row :gutter="32">
                                        <el-col :span="16">
                                            <el-form-item label="需要学习监控" prop="playExam">
                                                <el-radio-group v-model="info.playExam">
                                                    <el-radio :label="0">不需要</el-radio>
                                                    <el-radio :label="1">弹题验证</el-radio>
                                                    <el-radio :label="2">滑块验证</el-radio>
                                                    <el-radio :label="3">实时监控</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" v-show="info.playExam!==0">
                                            <el-form-item label="监控频次" prop="playExamMinute">
                                                <el-input v-model="info.playExamMinute" placeholder="请输入次/分钟">
                                                    <span slot="append">分钟</span>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="能否快进播放" prop="fastForward">
                                                <el-radio-group v-model="info.fastForward">
                                                    <el-radio :label="0">否</el-radio>
                                                    <el-radio :label="1">是</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="每日最多学习时长" prop="dayMaxHour">
                                                <el-input v-model="info.dayMaxHour" placeholder="请输入每日最多学习时长（0不限）">
                                                    <span slot="append">分钟</span>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="报名信息设置" name="m4">
                                    <el-row :gutter="32">
                                        <el-col :span="12">
                                            <el-form-item label="男生年龄段">
                                                <div style="display: flex">
                                                    <el-input v-model="info.maleMinAge" placeholder="最小年龄" style="width: 150px">
                                                        <span slot="append">岁</span>
                                                    </el-input>
                                                    <span style="width: 30px; text-align: center">至</span>
                                                    <el-input v-model="info.maleMaxAge" placeholder="最大年龄" style="width: 150px">
                                                        <span slot="append">岁</span>
                                                    </el-input>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="女生年龄段">
                                                <div style="display: flex">
                                                    <el-input v-model="info.femaleMinAge" placeholder="最小年龄"  style="width: 150px">
                                                        <span slot="append">岁</span>
                                                    </el-input>
                                                    <span style="width: 30px; text-align: center">至</span>
                                                    <el-input v-model="info.femaleMaxAge" placeholder="最大年龄"  style="width: 150px">
                                                        <span slot="append">岁</span>
                                                    </el-input>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="证书照片底色">
                                                <el-select v-model="info.headImgBgcolor" placeholder="请选择">
                                                    <el-option label="蓝底" value="蓝底"></el-option>
                                                    <el-option label="红底" value="红底"></el-option>
                                                    <el-option label="白底" value="白底"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row :gutter="32">
                                        <el-col :span="24">
                                            <el-divider content-position="left">报名资料</el-divider>
                                        </el-col>
                                        <el-col :span="7" v-for="(item, indx) in info.configList" :key="indx">
                                            <el-form-item :label="item.fieldName">
                                                <div style="display: flex; flex-direction: row;">
                                                    <el-checkbox size="mini" v-model="item.needInput" @change="checked=>configItemChange(checked, item)" :true-label="1" :false-label="0">录入</el-checkbox>
                                                    <el-checkbox size="mini" v-model="item.required" :true-label="1" :false-label="0">必填</el-checkbox>
                                                    <el-input v-show="item.hasRemarks===1" v-model="item.remarks" placeholder="请输入提示说明"  style="margin-left: 20px"/>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="报名自定义信息" name="m4_1">
                                    <ClassDefFieldEditor :filedList.sync="info.filedList"></ClassDefFieldEditor>
                                </el-tab-pane>
                                <el-tab-pane label="自定义文件" name="m4_4">
                                    <ClassDefFileEditor :fileList.sync="info.fileList"></ClassDefFileEditor>
                                </el-tab-pane>
                                <el-tab-pane label="报名承诺书" name="m4_2">
                                    <el-input
                                        type="textarea"
                                        :autosize="{ minRows: 5, maxRows: 10}"
                                        placeholder="请输入内容"
                                        v-model="info.enrollAgree">
                                    </el-input>
                                </el-tab-pane>
                                <el-tab-pane label="报名学习范围" name="m4_3" v-if="info.classType==='LEARN'">
                                    <el-row :gutter="32" >
                                        <el-col :span="8">
                                            <el-form-item label="部门范围" prop="deptRange">
                                                <el-radio-group v-model="info.deptRange" @change="getDeptTree">
                                                    <el-radio :label="0">全部部门</el-radio>
                                                    <el-radio :label="1">指定部门</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="14" v-if="info.deptRange===1">
                                            <el-tree ref="deptTree"
                                                     node-key="deptId"
                                                     :data="deptList"
                                                     :props="{label: 'deptName', children: 'children'}"
                                                     :default-checked-keys="checkedKeys"
                                                     :check-on-click-node="true"
                                                     :expand-on-click-node="false"
                                                     show-checkbox
                                                     default-expand-all
                                                     highlight-current>
                                            </el-tree>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="分享设置" name="m5">
                                    <el-row :gutter="32">
                                        <el-col :span="8">
                                            <el-form-item label="是否可分享" prop="shareSign">
                                                <el-radio-group v-model="info.shareSign">
                                                    <el-radio :label="0">否</el-radio>
                                                    <el-radio :label="1">是</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="32">
                                        <el-col :span="24" v-show="info.shareSign===1">
                                            <el-form-item label="班级/分享标题" prop="shareTitle">
                                                <el-input v-model="info.shareTitle" placeholder="请输入班级/分享标题"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-divider v-if="info.shareSign===1" content-position="left">普通用户</el-divider>
                                    <el-row :gutter="32" v-show="info.shareSign===1">
                                        <el-col :span="12">
                                            <el-form-item label="分成类型" prop="rateType1">
                                                <el-radio-group v-model="info.rateType1">
                                                    <el-radio :label="1">按百分比(1-100)</el-radio>
                                                    <el-radio :label="2">按金额</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="分成比例%/金额(元)" prop="rateValue1" label-width="200px">
                                                <el-input v-model="info.rateValue1" placeholder="请输入比例%/金额(元)"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-divider v-if="info.shareSign===1" content-position="left">已申请用户（推广专员）</el-divider>
                                    <el-row :gutter="32" v-show="info.shareSign===1">
                                        <el-col :span="12">
                                            <el-form-item label="分成类型" prop="rateType2">
                                                <el-radio-group v-model="info.rateType2">
                                                    <el-radio :label="1">按百分比(1-100)</el-radio>
                                                    <el-radio :label="2">按金额</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="分成比例%/金额(元)" prop="rateValue2" label-width="200px">
                                                <el-input v-model="info.rateValue2" placeholder="请输入比例%/金额(元)"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                            </el-tabs>
                        </el-collapse-item>
<!--                        <el-collapse-item title="学习设置" name="c3">-->

<!--                        </el-collapse-item>-->
<!--                        <el-collapse-item title="报名信息设置" name="c4">-->

<!--                        </el-collapse-item>-->
                        <el-collapse-item title="班级介绍" name="c5">
                            <template slot="title">
                                <span class="title">班级介绍</span>
                            </template>
                            <el-row>
                                <el-col :span="24">
                                    <editor ref="editor" :height="300"></editor>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                    <!--                    <el-row :gutter="32">-->
                    <!--                        <el-col :span="18">-->
                    <!--                            <el-form-item label="海报图片">-->
                    <!--                                <SingleUploadImg @callback="handleUpload3" :imageUrl="info.posterImgUrl"-->
                    <!--                                                 :width="100" :height="150"></SingleUploadImg>-->
                    <!--                            </el-form-item>-->
                    <!--                        </el-col>-->
                    <!--                    </el-row>-->
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="openDrawer = false">取消</el-button>
                <el-button type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
        <!-- 预览侧边栏 -->
        <el-drawer
            title="分享预览"
            :visible.sync="viewDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            size="40%"
        >
            <div class="demo-drawer__content">
                <el-row :gutter="32">
                    <el-col v-html="info.shareContent"></el-col>
                </el-row>
                <ek-row>
                    <el-col>
                        <el-button type="primary" round>立即报名</el-button>
                    </el-col>
                </ek-row>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="viewDrawer = false">确定</el-button>
            </div>
        </el-drawer>
        <!-- 班级负责人侧边栏 -->
        <el-drawer
            title="班级负责人"
            :visible.sync="managerDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            size="60%"
        >
            <div class="demo-drawer__content">
                <class-manager ref="classManger"></class-manager>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="managerDrawer = false">关 闭</el-button>
            </div>
        </el-drawer>
        <!-- 考试安排侧边栏 -->

        <ClassExamEditor ref="classExamEditor" @callback="classExamDialog=false"></ClassExamEditor>

        <!-- 证书侧边栏 -->
        <el-dialog
            title="证书设置"
            :visible.sync="certDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form ref="certForm" :model="certInfo" label-suffix=":" label-width="160px" :rules="certValidate">
                <el-form-item label="发证机构" prop="orgId" v-if="this.privRange.havePriv()">
                    <el-select v-model="certInfo.orgId">
                        <el-option value="">请选择</el-option>
                        <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"
                                   :key="item.orgId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发证机构" v-else>
                    {{this.appCookies.getCookieONAME()}}
                </el-form-item>
                <el-form-item label="证书模板" prop="certTmplId">
                    <el-select v-model="certInfo.certTmplId" @change="showCertImg">
                        <el-option value="">请选择</el-option>
                        <el-option v-for="item in certTmplList" :value="item.certTmplId" :label="item.certTmplName"
                                   :key="item.certTmplId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <img v-show="certInfo.certTmplImgUrl" :src="certInfo.certTmplImgUrl"
                         style="height: auto; width: 200px">
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="certDialog = false">关 闭</el-button>
                <el-button type="primary" @click="toTest(certInfo.classId)">测试（先保存）</el-button>
                <el-button v-if="certInfo.certId" type="primary" @click="deleteCert(certInfo.certId)">删除设置</el-button>
                <el-button type="primary" @click="saveCert()">保 存</el-button>
            </span>
        </el-dialog>
        <ImageSelectDialog ref="imageSelectDialog" @callback="imageCallback"></ImageSelectDialog>
        <CourseSelect ref="courseSelect" @callback="selectCourseCallback"></CourseSelect>
        <ExamRuleSelect ref="examRuleSelect" @callback="ruleSelectCallback"></ExamRuleSelect>
        <MiniShareDialog ref="miniShareDialog"></MiniShareDialog>
        <el-dialog
            title="预览"
            :visible.sync="previewDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="70%">
            <img :src="certImgUrl" style="width: 100%; height: auto">
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import GnvqTree from "@v/components/GnvqTree";
import GnvqSelect from '@v/components/GnvqSelect';
import SingleUploadImg from "@c/upload/SingleUploadImg";
import ClassExamEditor from "./components/ClassExamEditor";
import ClassManager from "./components/ClassManager";
import ImageSelectDialog from "../components/ImageSelectDialog"
import editor from '../../components/editor/editor'
import CourseSelect from "./components/CourseSelect"
import ExamRuleSelect from "./components/ExamRuleSelect"
import MiniShareDialog from "@v/course/components/MiniShareDialog";
import ClassDefFieldEditor from "@v/course/components/ClassDefFieldEditor";
import ClassDefFileEditor from "@v/course/components/ClassDefFileEditor";

// import GroupEditorSelect from "@v/components/GroupEditorSelect.vue";
export default {
    components: {
        Pagination,
        Breadcrumb,
        GnvqTree,
        GnvqSelect,
        SingleUploadImg,
        ClassExamEditor,
        ClassManager,
        ImageSelectDialog,
        editor,
        CourseSelect,
        ExamRuleSelect,
        MiniShareDialog,
        // GroupEditorSelect,
        ClassDefFieldEditor,
        ClassDefFileEditor
    },
    filters: {
        classTypeFilter(status) {
            const statusMap = {
                'ENROLL': '考试报名',
                'LEARN': '继续教育',
            };
            return statusMap[status]
        },
        shareSignFilter(status) {
            const statusMap = {
                0: '否',
                1: '是',
            };
            return statusMap[status]
        },
        dataStatusFilter(status) {
            const statusMap = {
                0: '进行中',
                1: '已结束',
            };
            return statusMap[status]
        },
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger',
            };
            return statusMap[status]
        },
        colorFilter2(status) {
            const statusMap = {
                0: 'danger',
                1: 'success',
            };
            return statusMap[status]
        },
    },
    data() {
        const validateAmount = (rule, value, callback) => {
            let reg = /^\d+\.?\d{0,2}$/;
            if (value) {
                if (!reg.test(value)) {
                    callback(new Error('仅支持数字和小数点后两位'));
                    return;
                }
                callback();
                return;
            } else if (value === 0) {
                callback();
                return;

            } else {
                callback(new Error('报名费用格式不正确'));
                return;
            }
        };
        return {
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '班级管理'
                }
            ],
            accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
            cellIndex: '',
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                deleteStatus: '0',
                dataStatus: '',
                bannerStatus: '',
                confirmStatus: '',
                orgId: this.appCookies.getCookieTOID(),
                companyId: this.appCookies.getCookieOID(),
                userId: this.appCookies.getCookieUID(),
                gnvqCatId: '',
                gnvqId: '',
                levelId: '',
                classType: '',
                yearNum: '',
                classNum: '',
            },
            showMore: false,
            tableData: [],
            info: {
                orderNum: 0,
                endDate: '',
                enrollAgree: '',
                filedList: [],
                fileList: [],
                trainId: 0,
                examLoginWay: 0,
                headImgBgcolor: '蓝底',
                exerciseGroupId: '',
                canExerciseFreq: 0
            },
            signQrUrl: '',
            checkedKeys: [],
            deptList: [],
            activeNames: ["c1", "c2", "c3", "c4", "c5"],
            gnvqCatList: [],
            gnvqList: [],
            levelList: [],
            teacherList: [],
            teacherFinding: false,
            tableLoading: false,
            openDrawer: false,
            viewDrawer: false,
            videoModal: false,
            ruleValidate: {
                classType: [
                    {
                        required: true,
                        message: '请选择开班类型',
                        trigger: 'change'
                    }
                ],
                enrollType: [
                    {
                        required: true,
                        message: '请选择报名方式',
                        trigger: 'change'
                    }
                ],
                yearNum: [
                    {
                        required: true,
                        message: '请输入年份',
                        trigger: 'blur'
                    }
                ],
                classNum: [
                    {
                        required: true,
                        message: '请输入第几期',
                        trigger: 'blur'
                    }
                ],
                amount: [
                    {
                        required: true,
                        validator: validateAmount,
                        message: '请输入报名金额',
                        trigger: 'blur'
                    }
                ],
                fastForward: [
                    {
                        required: true,
                        type: "number",
                        message: '能否快进播放',
                        trigger: 'change'
                    }
                ],
                playExam: [
                    {
                        required: true,
                        type: "number",
                        message: '播放中是否弹题',
                        trigger: 'change'
                    }
                ],
                gnvqCatId: [
                    {
                        required: true,
                        type: 'number',
                        message: '请选择工种类别',
                        trigger: 'change'
                    }
                ],
                gnvqId: [
                    {
                        required: true,
                        type: 'number',
                        message: '请选择工种',
                        trigger: 'change'
                    }
                ],
                // levelId: [
                //     {
                //         required: true,
                //         type: 'number',
                //         message: '请选择工种等级',
                //         trigger: 'change'
                //     }
                // ],
            },
            certValidate: {
                orgId: [
                    {
                        required: true,
                        type: "number",
                        message: '请选择发证机构',
                        trigger: 'change'
                    }
                ],
                certTmplId: [
                    {
                        required: true,
                        type: "number",
                        message: '请选择证书模板',
                        trigger: 'change'
                    }
                ],
            },
            styles: {
                height: 'calc(100% - 55px)',
                overflow: 'auto',
                paddingBottom: '53px',
                position: 'static'
            },
            gnvqTreeData: [],
            orgList: [],
            managerDrawer: false,
            classExamDialog: false,
            certDialog: false,
            certInfo: {
                certTmplImgUrl: ''
            },
            certTmplList: [],
            configList: [],
            previewDialog: false,
            certImgUrl: '',
            trainPlanList: [],
        };
    },
    mounted() {
        this.initTable();
        this.$nextTick(function () {
            this.getOrgList();
            this.getCertList();
            this.getClassConfigList();
        });
    },
    computed: {},
    methods: {
        //上传首页轮播图图片组件的callback
        bannerImgCallback: function (webPath, savePath,) {
            this.$set(this.info, 'bannerImgUrl', webPath);
            this.$set(this.info, 'bannerImg', savePath);
        },
        toEditCert: function (classId) {
            this.certDialog = true;
            this.certInfo = {
                orgId: this.appCookies.getCookieOID(),
                classId: classId,
                certId: '',
                certTmplId: '',
                certTmplImgUrl: ''
            }
            this.$nextTick(() => {
                this.apis.get('course/class/cert/getByClassId?classId=' + classId + '&orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            if (res.data) {
                                this.certInfo.certTmplImgUrl = res.data.certTmplImgUrl;
                                this.certInfo.certTmplId = res.data.certTmplId;
                                this.certInfo.certId = res.data.certId;
                            }
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            })
        },
        saveCert: function () {
            this.$refs['certForm'].validate((valid) => {
                if (valid) {
                    let url = "course/class/cert/save";
                    // this.certInfo.orgId = this.appCookies.getCookieOID(),
                    this.certInfo.createBy = this.appCookies.getCookieUID();
                    this.certInfo.createByname = this.appCookies.getCookieUNAME();
                    this.certInfo.modifyBy = this.appCookies.getCookieUID();
                    this.certInfo.modifyByname = this.appCookies.getCookieUNAME();
                    // 保存数据
                    this.apis.postJson(url, this.certInfo)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true,
                                    offset: 200,
                                    type: 'success'
                                });
                                this.certDialog = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true,
                                    offset: 50,
                                    type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        deleteCert: function (certId) {
            let title = '确定要删除证书设置？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    certId: certId,
                    deleteStatus: 1,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/class/cert/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '删除成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                            this.certDialog = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toTest: function (classId) {
            this.mainLoading = true;
            let data = {
                classId: classId
            }
            this.apis.postForm('user/cert/jieye/genCertTest', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.certImgUrl = res.data;
                        this.previewDialog = true;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 200,
                            type: 'success'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        showCertImg: function (val) {
            let tmpl = this.certTmplList.filter(item => {
                return item.certTmplId === val
            })
            this.certInfo.certTmplImgUrl = tmpl[0].certTmplImgUrl;
            this.$forceUpdate()
        },
        getCertList: function () {
            this.apis.get('system/certtmpl/getList?useStatus=1&certTmplType=CERT&orgId=' + this.appCookies.getCookieTOID())
                .then((res) => {
                    if (res.code === 1) {
                        this.certTmplList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getClassConfigList: function () {
            this.apis.get('course/class/config/getList?classId=0')
                .then((res) => {
                    if (res.code === 1) {
                        this.configList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toEditClassExam: function (gnvqCatId, gnvqId, levelId, classId) {
            this.$nextTick(() => {
                this.$refs.classExamEditor.init(gnvqCatId, gnvqId, levelId, classId);
            })
        },
        getTrainPlanList: function (orgId) {
            this.apis.get('train/plan/getCurrListByOrg?orgId=' + orgId)
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.trainPlanList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getOrgList: function () {
            if (!this.privRange.havePriv()) {
                return
            }
            this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.orgList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toQuery: function (data) {
            this.cellIndex = '-1';
            this.queryParams.gnvqCatId = '';
            this.queryParams.gnvqId = '';
            this.queryParams.levelId = '';
            if (data.type === 'CAT') {
                this.queryParams.gnvqCatId = data.id;
            } else if (data.type === 'INFO') {
                this.queryParams.gnvqCatId = data.categoryId;
                this.queryParams.gnvqId = data.id;
            } else {
                this.queryParams.gnvqCatId = data.categoryId;
                this.queryParams.gnvqId = data.parentId;
                this.queryParams.levelId = data.id;
            }
            this.refreshTable()
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('course/class/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        toAdd: function () {
            let myDate = new Date();
            let tYear = myDate.getFullYear();
            let tMonth = myDate.getMonth();

            let configJson =  JSON.parse(JSON.stringify(this.configList));

            this.info = {
                orderNum: 0,
                trainId: 0,
                gnvqCatId: this.queryParams.gnvqCatId,
                gnvqId: this.queryParams.gnvqId,
                levelId: this.queryParams.levelId,
                orgId: this.queryParams.orgId,
                companyId: this.queryParams.companyId,
                yearNum: tYear.toString(),
                classNum: tMonth.toString(),
                shareSign: 0,
                rateType1: 2,
                rateValue1: 0,
                rateType2: 2,
                rateValue2: 0,
                fastForward: 1,
                playExam: 0,
                playExamMinute: 0,
                needBankno: 0,
                bannerStatus: 0,
                enrollType: 1,
                configList: configJson,
                courseList: [],
                maleMinAge: 18,
                maleMaxAge: 59,
                femaleMinAge: 18,
                femaleMaxAge: 54,
                filedList: [],
                fileList: [],
                examRuleId: '',
                examRuleName: '',
                canExercise: 1,
                canExam: 1,
                examLoginWay: 0,
                canCourse: 0,
                deptRange: 0,
                canExerciseFreq: 0,
                exerciseGroupId: '',
                headImgBgcolor: '蓝底',
                enrollAgree: '本人保证所提交个人信息资料及相关申请材料真实、准确。如果所提交个人信息资料及相关申请材料不真实、不准确，在职业技能鉴定申请中，本人愿意随时接受职业技能鉴定机构相关处理并承担相应后果。'
            };
            this.getTrainPlanList(this.info.orgId)
            this.$nextTick(function () {
                this.$refs.editor.setHtml("");
            });
            this.openDrawer = true;
        },
        onOrgChange: function (val) {
            this.getTrainPlanList(this.info.companyId)
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    if (this.info.endDate === undefined || this.info.endDate === null || this.info.endDate === '') {
                        this.$message({
                            message: '请选择结束时间',
                            showClose: true,
                            offset: 200,
                            type: 'success'
                        });
                        return;
                    }

                    let url = "course/class/save";
                    this.info.shareContent = this.$refs.editor.getHtml();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME();
                    if (this.info.deptRange===1) {
                        this.info.deptIds = this.getSelectDept();
                    }
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.openDrawer = false;
                                this.$message({
                                    message: '保存成功',
                                    showClose: true,
                                    offset: 200,
                                    type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true,
                                    offset: 50,
                                    type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (keyId) {
            this.apis.get('course/class/getById?classId=' + keyId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.getTrainPlanList(this.info.compnayId)
                        this.openDrawer = true;
                        this.$nextTick(function () {
                            this.$refs.editor.setHtml(this.info.shareContent);
                        });
                        this.getDeptTree(this.info.deptRange)
                        // this.info.bannerType = this.info.bannerType.toString()
                        // console.log(this.jsonValue)
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDelete: function (id, deleteStatus) {
            let title = '确定要删除此条数据？';
            if (deleteStatus === 0) {
                title = '确定要恢复此条数据？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    classId: id,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/class/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        tofinish: function (id, dataStatus) {
            this.$confirm("确定要结束？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    classId: id,
                    dataStatus: dataStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/class/finish', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                            this.refreshTable()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                    });
            }).catch(() => {
            });
        },
        toLock: function (row) {
            let data = {
                classId: row.classId,
                confirmStatus: row.confirmStatus,
                operUserId: this.appCookies.getCookieUID(),
                operUserName: this.appCookies.getCookieUNAME()
            };
            this.apis.postForm('course/class/confirm', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true,
                            offset: 200,
                            type: 'success'
                        });
                        this.initTable();
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 50,
                            type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toBanner: function (row) {
            let data = {
                classId: row.classId,
                bannerStatus: row.bannerStatus,
                operUserId: this.appCookies.getCookieUID(),
                operUserName: this.appCookies.getCookieUNAME()
            };
            this.apis.postForm('course/class/bannerStatus', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true,
                            offset: 200,
                            type: 'success'
                        });
                        this.initTable();
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 50,
                            type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toReview: function (keyId) {
            this.apis.get('course/class/getById?classId=' + keyId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.info = res.data;
                        this.viewDrawer = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toManager: function (classId, orgId) {
            this.managerDrawer = true;
            this.$nextTick(function () {
                this.$refs.classManger.initData(classId, orgId);
            });
        },
        handleUpload3: function (webPath, savePath,) {
            this.$set(this.info, 'posterImgUrl', webPath);
            this.$set(this.info, 'posterImg', savePath);
        },
        configItemChange: function (val, item) {
            if (val===0) {
                item.required = 0
            }
        },
        dropdownValue: function (type, row) {
            return {
                'type': type, //key 是你自己想要的字段，值自己定义
                'data': row
            };
        },
        dropdownOperate: function (command) {
            console.log(command)
            if (command.type === 'review') {
                this.toReview(command.data.classId);
            } else if (command.type === 'delete') {
                this.toDelete(command.data.classId, 1);
            } else if (command.type === 'recover') {
                this.toDelete(command.data.classId, 0);
            } else if (command.type === 'finish') {
                this.tofinish(command.data.classId, 1);
            } else if (command.type === 'unfinish') {
                this.tofinish(command.data.classId, 0);
            } else if (command.type === 'manager') {
                this.toManager(command.data.classId, command.data.orgId);
            } else if (command.type === 'classExam') {
                this.toEditClassExam(command.data.gnvqCatId, command.data.gnvqId, command.data.levelId, command.data.classId);
            } else if (command.type === 'cert') {
                this.toEditCert(command.data.classId);
            } else if (command.type === 'miniShare') {
                this.toGenMiniShare(command.data.classId, command.data.orgName);
            } else if (command.type === 'signShare') {
                this.toGenSignShare(command.data.classId, command.data.orgName);
            }
        },
        toSelectClassImage: function () {
            this.$refs.imageSelectDialog.open();
        },
        imageCallback: function (webPath, savePath) {
            this.$set(this.info, 'classImgUrl', webPath);
            this.$set(this.info, 'classImg', savePath);
        },
        toSelectExamRule: function () {
            this.$refs.examRuleSelect.open(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
        },
        ruleSelectCallback: function (ruleId, ruleName) {
            this.$set(this.info, 'examRuleId', ruleId)
            this.$set(this.info, 'examRuleName', ruleName)
        },
        toSelectCourse: function () {
            this.$refs.courseSelect.open(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
        },
        deleteCourse: function (index) {
            let classCourse = this.info.courseList[index];
            if (classCourse.classCourseId) {
                let data = {
                    classCourseId: classCourse.classCourseId,
                    deleteStatus: 1,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/class/course/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info.courseList.splice(index, 1);
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            } else {
                this.info.courseList.splice(index, 1);
            }
            // this.$forceUpdate();
        },
        selectCourseCallback: function (clist) {
            if (!this.info.courseList) {
                this.info.courseList = []
            }
            clist.forEach(course => {
                let hasExist = false;
                this.info.courseList.forEach(item => {
                    if (item.courseId === course.courseId) {
                        hasExist = true;
                    }
                })
                if (!hasExist) {
                    this.info.courseList.push({
                        courseId: course.courseId,
                        courseName: course.courseName,
                        courseImgUrl: course.courseImgUrl,
                        courseTime: course.courseTime
                    })
                }
            })
            this.$forceUpdate();

        },
        toGenMiniShare: function (classId, orgName) {
            this.$refs.miniShareDialog.open(classId, orgName);
        },
        toGenSignShare: function (classId, orgName) {
            this.$refs.miniShareDialog.open(classId, orgName);
        },
        getSignQrUrl: function (classId) {
            this.signQrUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=QIANMING_CLASS&bizId=' + classId + "&tenantId=" + this.appCookies.getTenantId();
        },
        getDeptTree: function (val) {
            if (val === 1 && this.info.orgId) {
                this.apis.get('system/dept/getList?orgId='+this.info.orgId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.deptList = res.data || [];
                            if (this.info.deptIds.length > 0) {
                                this.findSelected(this.deptList)
                            }
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            } else {
                this.info.deptRange = 0
            }
        },
        findSelected: function (treeData) {
            let _this = this;
            treeData.forEach(function (item) {
                if (_this.info.deptIds.indexOf(item.deptId) > -1) {
                    _this.checkedKeys.push(item.deptId);
                }
                if (item.children) {
                    _this.findSelected(item.children);
                }
            });
        },
        getSelectDept: function () {
            let deptIds = []
            let ids = this.$refs.deptTree.getCheckedKeys();
            let halfIds = this.$refs.deptTree.getHalfCheckedKeys();
            deptIds = ids.concat(halfIds)
            return deptIds;
        },
    }
}
</script>

<style lang="scss" scoped>
.table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .left {
        height: 100px;
        width: 140px;
        flex-shrink:0;

        img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }

    .center {
        height: 120px;
        flex: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
            .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                margin-bottom: 6px;
                .label {
                    text-align: left;
                    font-weight: bold;
                    line-height: 1.5;
                }
                .value {
                    text-align: left;
                    padding-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .strong {
                    font-weight: bold;
                }
            }
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .item-info {
                height: 100%;
                padding: 0 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;;
                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .label {
                        min-width: 60px;
                        text-align: right;
                        font-weight: bold;
                        line-height: 1.5;
                    }
                    .value {
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .strong {
                        font-weight: bold;
                    }
                }
            }
        }

        .footer {

        }
    }
}

.oper-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
}
.course-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 4px;
    ::v-deep .el-card {
        margin: 10px 20px;
    }
    .image {
        width: 200px;
        height: 130px;
    }
    .course-add {
        width: 200px;
        height: 150px;
        margin: 10px 20px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        outline: none;
        .icon {
            //width: 120px;
            //height: 60px;
            line-height: 150px;
            font-size: 30px;
            font-weight: 400;
            color: #999999;
        }
    }
}
</style>
