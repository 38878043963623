<template>

    <el-drawer
        title="考试安排"
        :visible.sync="classExamDialog"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        size="90%"
    >
        <div class="demo-drawer__content">
            <el-divider content-position="left">考试信息编辑</el-divider>
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="160px" label-suffix=":">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="examType">
                            <el-radio-group v-model="info.examType">
                                <el-radio :label="1">理论考试</el-radio>
                                <el-radio :label="2">实操考试</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="考试方式" prop="examWay">
                            <el-radio-group v-model="info.examWay">
                                <el-radio :label="1">线上</el-radio>
                                <el-radio :label="2">线下</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="考试时长" prop="examTime">
                            <el-input v-model="info.examTime" placeholder="请输入考试时长">
                                <template slot="append">分钟</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="认证方式" prop="loginWay">
                            <el-radio-group v-model="info.loginWay">
                                <el-radio :label="0">不认证</el-radio>
                                <el-radio :label="1">人脸识别</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="监控方式" prop="verifyWay">
                            <el-radio-group v-model="info.verifyWay">
                                <el-radio :label="0">不监控</el-radio>
<!--                                <el-radio :label="1">人脸识别认证</el-radio>-->
                                <el-radio :label="2">人脸拍照监控</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="人脸监控拍照频次" prop="photoFrqMin">
                            <el-input v-model="info.photoFrqMin" placeholder="请输入频次">
                                <template slot="append">分钟</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="最少答题时间" prop="minAnswerTime">
                            <el-input v-model="info.minAnswerTime" placeholder="请输入最少答题时间">
                                <template slot="append">分钟</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开考后几分钟禁止考试" prop="notAfterTime">
                            <el-input v-model="info.notAfterTime" placeholder="请输入开考后几分钟禁止考试">
                                <template slot="append">分钟</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="中途可退出次数" prop="exitTimes">
                            <el-input v-model="info.exitTimes" placeholder="请输入中途可退出次数">
                                <template slot="append">次</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="及格分数" prop="passScore">
                            <el-input v-model="info.passScore" placeholder="请输入及格分数">
                                <template slot="append">分</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="考试试卷" prop="examRuleId">
                            <el-input v-model="info.examRuleName" :readonly="true">
                                <el-button @click="toSelectExamRule" slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否需要预约" prop="subscribe">
                            <el-radio-group v-model="info.subscribe">
                                <el-radio :label="0">不需要</el-radio>
                                <el-radio :label="1">需要</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="info.subscribe!=null">
                    <el-col :span="24">
                        <el-form-item label="场次安排">
                            <div class="scheld-list" v-for="(item, index) in info.schedList" :key="index" style="margin: 5px 0">
                                <div class="scheld-num">
                                    <span>场次{{index+1}}:</span>
                                </div>
                                <div class="scheld-row">
                                    <div class="row-cell">
                                        <el-form-item label="开始日期时间" prop="subscribe">
                                            <el-date-picker style="width: 150px"
                                                            v-model="item.startDate"
                                                            type="date"
                                                            value-format="yyyy-MM-dd"
                                                            placeholder="开始日期">
                                            </el-date-picker>
                                            <el-time-select style="width: 100px; margin-left: 10px"
                                                            placeholder="开始时间"
                                                            v-model="item.startTime"
                                                            :picker-options="{
                                                  start: '07:00',
                                                  step: '00:15',
                                                  end: '22:00'
                                                }">
                                            </el-time-select>
                                        </el-form-item>
                                    </div>
                                    <div class="row-cell">
                                        <el-form-item label="结束日期时间" prop="subscribe">
                                            <el-date-picker style="width: 150px"
                                                            v-model="item.endDate"
                                                            type="date"
                                                            value-format="yyyy-MM-dd"
                                                            placeholder="结束日期">
                                            </el-date-picker>
                                            <el-time-select style="width: 100px; margin-left: 10px"
                                                            placeholder="结束时间"
                                                            v-model="item.endTime"
                                                            :picker-options="{
                                                  start: '07:00',
                                                  step: '00:15',
                                                  end: '22:00'
                                                }">
                                            </el-time-select>
                                        </el-form-item>
                                    </div>
                                    <div class="row-cell">
                                        <el-form-item label="考试地点">
                                            <el-input type="text" v-model="item.examAddress" placeholder="地点"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="row-cell">
                                        <el-form-item label="经纬度">
                                            <div style="display: flex; flex-direction: row">
                                                <el-input v-model="item.lng" placeholder="请输入经度"></el-input>
                                                <span style="margin: 0 5px">-</span>
                                                <el-input v-model="item.lat" placeholder="请输入纬度"></el-input>
                                                <el-button size="mini" icon="el-icon-location"
                                                           circle style="margin-left: 4px"
                                                           @click="showMarkMap(item)"></el-button>
                                            </div>
                                            <div><i class="el-icon-question" style="color: #999999"></i>例如：北京（<span style="margin-right: 20px">经度：116.4</span><span>纬度：39.9</span>）</div>
                                        </el-form-item>
                                    </div>
                                    <div class="row-cell">
                                        <el-form-item label="可预约人数">
                                            <el-input type="text" v-model="item.maxSubNum" placeholder="可预约人数"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="row-cell">
                                        <el-form-item label="交卷后公布成绩" prop="openScore">
                                            <el-checkbox :true-label="1" :false-label="0" v-model="item.openScore"></el-checkbox>
                                        </el-form-item>
                                    </div>
                                    <div class="row-cell">
                                        <el-form-item label="考前签到时间">
                                            <el-input type="text" v-model="item.signinLimitTime" placeholder="请输入">
                                                <template slot="append">分钟</template>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="row-cell">
                                        <el-form-item label="签到有效距离">
                                            <el-input type="text" v-model="item.signinDistance" placeholder="请输入">
                                                <template slot="append">米</template>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>

                                </div>
                                <div class="scheld-del"  v-show="info.subscribe===1">
                                     <el-button type="text" @click="optionsRemove(index)">删除</el-button>
                                </div>
                            </div>
                            <el-row :gutter="16"  v-show="info.subscribe===1">
                                <el-col :span="20" :offset="2">
                                    <el-button plain @click="optionsAdd" type="success" icon="el-icon-plus" style="width:100%">继续添加场次</el-button>
                                </el-col>
                            </el-row>
                            <MapMarkDialog ref="baiduMap" @callback="getgeocode"></MapMarkDialog>
                        </el-form-item>
                    </el-col>
                </el-row>
<!--                <el-row>-->
<!--                    <el-col :span="8">-->
<!--                        <el-form-item label="考试开始日期">-->
<!--                            <div style="display: flex; flex-direction: row">-->
<!--                                <el-form-item label="" prop="startDate">-->
<!--                                    <el-date-picker style="width: 150px"-->
<!--                                                    v-model="info.startDate"-->
<!--                                                    type="date"-->
<!--                                                    value-format="yyyy-MM-dd"-->
<!--                                                    placeholder="选择考试开始日期">-->
<!--                                    </el-date-picker>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="" prop="startTime">-->
<!--                                    <el-time-select style="width: 100px; margin-left: 10px"-->
<!--                                                    placeholder="开始时间"-->
<!--                                                    v-model="info.startTime"-->
<!--                                                    :picker-options="{-->
<!--                                                  start: '08:00',-->
<!--                                                  step: '00:30',-->
<!--                                                  end: '20:00'-->
<!--                                                }">-->
<!--                                    </el-time-select>-->
<!--                                </el-form-item>-->
<!--                            </div>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="8">-->
<!--                        <el-form-item label="考试结束日期">-->
<!--                            <div style="display: flex; flex-direction: row">-->
<!--                                <el-form-item label="" prop="endDate">-->
<!--                                    <el-date-picker style="width: 150px"-->
<!--                                                    v-model="info.endDate"-->
<!--                                                    type="date"-->
<!--                                                    value-format="yyyy-MM-dd"-->
<!--                                                    placeholder="选择考试结束日期">-->
<!--                                    </el-date-picker>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="" prop="endTime">-->
<!--                                    <el-time-select style="width: 100px; margin-left: 10px"-->
<!--                                                    placeholder="结束时间"-->
<!--                                                    v-model="info.endTime"-->
<!--                                                    :picker-options="{-->
<!--                                                  start: '08:00',-->
<!--                                                  step: '00:30',-->
<!--                                                  end: '20:00'-->
<!--                                                }">-->
<!--                                    </el-time-select>-->
<!--                                </el-form-item>-->
<!--                            </div>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="8">-->
<!--                        <el-form-item label="考试地点" prop="examAddress">-->
<!--                            <el-input v-model="info.examAddress" placeholder="请输入考试地点">-->
<!--                            </el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
                <el-row>
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <ExamRuleSelect ref="examRuleSelect" @callback="ruleSelectCallback"></ExamRuleSelect>
            <el-divider content-position="left">考试安排列表</el-divider>
            <el-row>
                <el-col :span="24" style="text-align: right">
                    <el-button type="success" icon="el-icon-plus" @click="toAdd">新建考试安排</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="16">
                <el-col :span="24">
                    <el-table
                        v-loading="tableLoading"
                        :data="tableData"
                        fit
                        stripe
                        highlight-current-row
                        style="width: 100%;"
                        ref="queryTable"
                        :indent="20"
                    >
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-table border :data="props.row.makeupList">
                                    <el-table-column label="补考日期" align="left">
                                        <template slot-scope="{row}">
                                            {{row.startDate}} {{row.startTime}} ~ {{row.endDate}} {{row.endTime}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                                        <template slot-scope="{row}">
                                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-edit" @click="toEditMarkup(row.classExamId)">编辑</el-button>
                                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.classExamId, 1)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="#"
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column label="类型" prop="examType" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.examType | examTypeFilter}}
                            </template>
                        </el-table-column>
                        <el-table-column label="方式" prop="examWay" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.examWay | examWayFilter}}
                            </template>
                        </el-table-column>
                        <el-table-column label="是否需要预约" prop="subscribe" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.subscribe | subscribeFilter}}
                            </template>
                        </el-table-column>
<!--                        <el-table-column label="考试日期" width="240px" align="center">-->
<!--                            <template slot-scope="{row}">-->
<!--                                {{row.startDate}} {{row.startTime}} ~ {{row.endDate}} {{row.endTime}}-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                        <el-table-column label="考试时长" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.examTime}}分钟
                            </template>
                        </el-table-column>
                        <el-table-column label="最少答题时间" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.minAnswerTime}}分钟
                            </template>
                        </el-table-column>
                        <el-table-column label="可退出次数" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.exitTimes}}次
                            </template>
                        </el-table-column>
                        <el-table-column label="及格分" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.passScore}}分
                            </template>
                        </el-table-column>

                        <el-table-column label="试卷" align="left"  :show-overflow-tooltip="true">
                            <template slot-scope="{row}">
                                {{row.examRuleName}}
                            </template>
                        </el-table-column>
<!--                        <el-table-column label="地点" align="left"  :show-overflow-tooltip="true">-->
<!--                            <template slot-scope="{row}">-->
<!--                                {{row.examAddress}}-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                        <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                            <template slot-scope="{row}">
                                <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-edit" @click="toEdit(row.classExamId)">编辑</el-button>
                                <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-edit" @click="toAddMarkup(row.classExamId)">补考安排</el-button>
                                <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.classExamId, 1)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="classExamDialog = false">关 闭</el-button>
        </div>
        <!-- 补考安排侧边栏 -->
        <el-dialog
            title="补考安排"
            :visible.sync="markuoDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="markupInfo" :rules="ruleValidate" ref="markuoForm" label-width="160px" label-suffix=":">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="考试开始日期">
                            <div style="display: flex; flex-direction: row">
                                <el-form-item label="" prop="startDate">
                                    <el-date-picker style="width: 150px"
                                                    v-model="markupInfo.startDate"
                                                    type="date"
                                                    value-format="yyyy-MM-dd"
                                                    placeholder="选择考试开始日期">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="" prop="startTime">
                                    <el-time-select style="width: 100px; margin-left: 10px"
                                                    placeholder="开始时间"
                                                    v-model="markupInfo.startTime"
                                                    :picker-options="{
                                                  start: '08:00',
                                                  step: '00:30',
                                                  end: '20:00'
                                                }">
                                    </el-time-select>
                                </el-form-item>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="考试结束日期">
                            <div style="display: flex; flex-direction: row">
                                <el-form-item label="" prop="endDate">
                                    <el-date-picker style="width: 150px"
                                                    v-model="markupInfo.endDate"
                                                    type="date"
                                                    value-format="yyyy-MM-dd"
                                                    placeholder="选择考试结束日期">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="" prop="endTime">
                                    <el-time-select style="width: 100px; margin-left: 10px"
                                                    placeholder="结束时间"
                                                    v-model="markupInfo.endTime"
                                                    :picker-options="{
                                                  start: '08:00',
                                                  step: '00:30',
                                                  end: '20:00'
                                                }">
                                    </el-time-select>
                                </el-form-item>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="markuoDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveMarkup('markuoForm')">保 存</el-button>
            </span>
        </el-dialog>
    </el-drawer>
</template>

<script>
import ExamRuleSelect from "./ExamRuleSelect"
import MapMarkDialog from "@c/map/MapMarkDialog";
export default {
    components: {
        ExamRuleSelect,
        MapMarkDialog
    },
    filters: {
        examTypeFilter(status) {
            const statusMap = {
                1: '理论考试',
                2: '实操考试',
            };
            return statusMap[status]
        },
        examWayFilter(status) {
            const statusMap = {
                1: '线上',
                2: '线下',
            };
            return statusMap[status]
        },
        subscribeFilter(status) {
            const statusMap = {
                0: '否',
                1: '是',
            };
            return statusMap[status]
        },
    },
    data() {
        return {
            classExamDialog: false,
            gnvqCatId: '',
            gnvqId: '',
            levelId: '',
            classId: '',
            info: {
                schedList: [
                    {
                        startDate: '',
                        startTime: '',
                        endDate: '',
                        endTime: '',
                        examAddress: '',
                        maxSubNum: '',
                        signinDistance: 500,
                        openScore: 1
                    }
                ]
            },
            tableData: [],
            tableLoading: false,
            ruleValidate: {
                examType: [
                    { required: true, message: '选择考试类型', trigger: 'change' }
                ],
                examWay: [
                    { required: true, message: '选择考试方式', trigger: 'change' }
                ],
                // startDate: [
                //     { required: true, message: '选择考试开始日期', trigger: 'change' }
                // ],
                // startTime: [
                //     { required: true, message: '选择开始时间', trigger: 'change' }
                // ],
                // endDate: [
                //     { required: true, message: '选择试结束日期', trigger: 'change' }
                // ],
                // endTime: [
                //     { required: true, message: '选择结束时间', trigger: 'change' }
                // ],
                examTime: [
                    { required: true, message: '请输入考试时长', trigger: 'blur' }
                ],
                loginWay: [
                    { required: true, message: '选择认证方式', trigger: 'change' }
                ],
                verifyWay: [
                    { required: true, message: '选择监控方式', trigger: 'change' }
                ],
                examRuleId: [
                    { required: true, message: '请选择考试试卷', trigger: 'blur' }
                ],
                subscribe: [
                    { required: true, message: '选择是否需要预约', trigger: 'change' }
                ],
                exitTimes: [
                    { required: true, message: '请输入可退出次数', trigger: 'blur' }
                ],
                passScore: [
                    { required: true, message: '请输入及格分数', trigger: 'blur' }
                ],
            },
            markuoDialog: false,
            markupInfo: {},
            currScheld: {}
        }
    },
    methods: {
        init: function (gnvqCatId, gnvqId, levelId, classId) {
            this.classExamDialog = true;
            this.gnvqCatId = gnvqCatId;
            this.gnvqId = gnvqId;
            this.levelId = levelId;
            this.classId = classId;
            this.info = {
                parentId: 0,
                schedList: [
                    {
                        startDate: '',
                        startTime: '',
                        endDate: '',
                        endTime: '',
                        examAddress: '',
                        maxSubNum: ''
                    }
                ]
            }
            this.getDataList();
        },
        showMarkMap: function (item) {
            this.currScheld = item
            this.$refs.baiduMap.initMap(this.currScheld.lat, this.currScheld.lng, this.currScheld.examAddress)
        },
        getgeocode(lng, lat) {
            console.log("经度：" + lng);
            console.log("纬度：" + lat);
            this.currScheld.lng = lng
            this.currScheld.lat = lat
            this.$forceUpdate()
        },
        getDataList: function () {
            this.apis.get('course/class/exam/mst/getByClassId?classId=' + this.classId)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data || [];
                    } else {
                        this.tableData = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toAdd: function () {
            this.$refs.editForm.resetFields()
            this.info = {
                parentId: 0,
                classId: this.classId,
                verifyWay: 0,
                photoFrqMin: 0,
                schedList: [
                    {
                        startDate: '',
                        startTime: '',
                        endDate: '',
                        endTime: '',
                        examAddress: '',
                        maxSubNum: ''
                    }
                ]
            }
        },
        saveData: async function () {
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    let url = "course/class/exam/mst/save";
                    this.info.classId = this.classId;
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.info = {
                                    parentId: 0
                                }
                                this.toAdd()
                                this.getDataList()
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (classExamId) {
            this.apis.get('course/class/exam/mst/getById?classExamId=' + classExamId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data || {};
                    } else {
                        this.info = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDelete: function (classExamId, deleteStatus) {
            let title = '确定要删除此条数据？';
            if (deleteStatus === 0) {
                title = '确定要恢复此条数据？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    classExamId: classExamId,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/class/exam/mst/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.getDataList();
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toSelectExamRule: function () {
            this.$refs.examRuleSelect.open(this.gnvqCatId, this.gnvqId, this.levelId);
        },
        ruleSelectCallback: function (ruleId, ruleName) {
            this.$set(this.info, 'examRuleId', ruleId)
            this.$set(this.info, 'examRuleName', ruleName)
        },
        toAddMarkup: function (classExamId) {
            this.markupInfo = {
                parentId: classExamId,
                classId: this.classId
            }
            this.markuoDialog = true
        },
        toEditMarkup: function (classExamId) {
            this.apis.get('course/class/exam/mst/getById?classExamId=' + classExamId)
                .then((res) => {
                    if (res.code === 1) {
                        this.markupInfo = res.data || {};
                        this.markuoDialog = true
                    } else {
                        this.markupInfo = {};
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        saveMarkup: async function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let url = "course/class/exam/mst/save";
                    this.markupInfo.classId = this.classId;
                    this.markupInfo.makeupFlag = 1;
                    this.markupInfo.createBy = this.appCookies.getCookieUID();
                    this.markupInfo.createByname = this.appCookies.getCookieUNAME() ;
                    this.markupInfo.modifyBy = this.appCookies.getCookieUID();
                    this.markupInfo.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.markupInfo)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.getDataList();
                                this.markuoDialog = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        //添加单选题选项
        optionsAdd: function () {
            if (this.info.schedList === undefined || this.info.schedList === null || this.info.schedList === '') {
                this.info.schedList = []
            }
            this.info.schedList.push({ startDate: '', startTime: '', endDate: '', endTime: '', examAddress: '', maxSubNum: '', signinLimitTime: '', signinDistance: 500, openScore: 1 });
        },
        //删除单选题选项
        optionsRemove: function (index) {
            this.info.schedList.splice(index, 1);
        },
    }
}
</script>
<style lang="scss" scoped>
.scheld-list {
    display: flex;
    flex-direction: row;
    border: 1px dotted #999999;
    padding: 15px 15px;
    .scheld-num {
        width: 100px;
        text-align: center;
        font-weight: bold;
    }
    .scheld-del {
        width: 100px;
        text-align: center;
    }
    .scheld-row {
        flex: 1;
        width: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .row-cell {
            flex: 0 0 50%;
            display: flex;
            flex-direction: row;
            ::v-deep .el-form-item {
                width: 100%;
            }
        }
    }
}
</style>
