<template>
    <div>
    <el-drawer
        title="报名信息"
        :visible.sync="openDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        :ref="orderId"
        direction="rtl"
        custom-class="demo-drawer"
        size="80%"
    >
        <div class="demo-drawer__content  input-collapse">
            <el-form ref="editForm" :model="orderInfo" label-suffix=":" label-width="120px">
                <el-collapse v-model="activeNames">
                    <el-collapse-item title="工种信息" name="c1">
                        <template slot="title">
                            <span class="title">工种信息</span>
                        </template>
                        <el-row :gutter="32">
                            <el-col :span="8">
                                <el-form-item label="工种">
                                    {{orderInfo.gnvqName}}-{{orderInfo.levelName}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="报名机构">
                                    {{orderInfo.orgName}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="班级">
                                    {{orderInfo.className}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息" name="c2">
                        <template slot="title">
                            <span class="title">基本信息</span>
                        </template>
                        <el-row :gutter="32">
                            <el-col :span="6">
                                <el-form-item label="姓名">
                                    {{orderInfo.trueName}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" v-if="orderInfo.classConfig.sex.needInput===1">
                                <el-form-item label="性别">
                                    {{orderInfo.sex === 1 ? '男' : '女'}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="6"  v-if="orderInfo.classConfig.idCard.needInput===1">
                                <el-form-item label="身份证号">
                                    {{orderInfo.idCard}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="6"  v-if="orderInfo.classConfig.nationName.needInput===1">
                                <el-form-item label="民族">
                                    {{orderInfo.nationName}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32">
                            <el-col :span="6">
                                <el-form-item label="联系电话">
                                    {{orderInfo.phone}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="18" v-if="orderInfo.classConfig.homeAddr.needInput===1">
                                <el-form-item label="家庭住址">
                                    {{orderInfo.homeAddr}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32">
                            <el-col :span="8" style="text-align: center" v-if="orderInfo.classConfig.certHeadImg.needInput===1">
                                <img @click="handleView(orderInfo.certHeadImgUrl, true, 'cert_head_img', '', orderInfo.certHeadImgOrgUrl)" class="img-prew" :src="orderInfo.certHeadImgUrl" style="width: 94px; height: 132px"/>
                                <p >{{orderInfo.headImgBgcolor}}照片</p>
                            </el-col>
                            <el-col :span="8" style="text-align: center" v-if="orderInfo.classConfig.idcardImg.needInput===1">
                                <img @click="handleView(orderInfo.idCardFrontUrl, true, 'id_card_front', '', orderInfo.idCardFrontOrgUrl)" class="img-prew" :src="orderInfo.idCardFrontUrl" style="width: 150px; height: 100px"/>
                                <p>身份证正面照片</p>
                            </el-col>
                            <el-col :span="8" style="text-align: center" v-if="orderInfo.classConfig.idcardImg.needInput===1">
                                <img @click="handleView(orderInfo.idCardBackUrl, true, 'id_card_back', '', orderInfo.idCardBackOrgUrl)" class="img-prew" :src="orderInfo.idCardBackUrl" style="width: 150px; height: 100px"/>
                                <p>身份证背面照片</p>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32" v-if="orderInfo.classConfig.education.needInput===1">
                            <el-col :span="6">
                                <el-form-item label="学历">
                                    {{orderInfo.educationName}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="毕业证书">
                                    <img v-for="(item, idx) in orderInfo.eduImgList" :key="idx"
                                         @click="handleView(item.fileUrl, true, '', item.fileId, item.fileOrgUrl)" class="img-prew" :src="item.fileUrl" style="width: 120px; height: 120px"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32" v-if="orderInfo.classConfig.workYears.needInput===1">
                            <el-col :span="6">
                                <el-form-item label="工龄">
                                    {{orderInfo.workYears}}年
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="工龄证明">
                                    <img v-for="(item, idx) in orderInfo.workYearsImgList" :key="idx"
                                         @click="handleView(item.fileUrl, true, '', item.fileId, item.fileOrgUrl)" class="img-prew" :src="item.fileUrl" style="width: 120px; height: 120px"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32" v-if="orderInfo.classConfig.certName.needInput===1">
                            <el-col :span="24">
                                <el-form-item label="证书">
                                    <el-row v-for="(item, index) in orderInfo.certList" :key="index">
                                        <el-col :span="6">
                                            {{item.certName}}
                                        </el-col>
                                        <el-col :span="18">
                                            <el-form-item label="证明文件">
                                                <img v-for="(itf, idx) in item.certFiles" :key="idx"
                                                     @click="handleView(itf.fileUrl, true, '', itf.fileId, itf.fileOrgUrl)" class="img-prew" :src="itf.fileUrl" style="width: 160px; height: 120px"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32" v-if="orderInfo.classConfig.signImg.needInput===1">
                            <el-col :span="24">
                                <el-form-item label="本人签名">
                                    <img v-if="orderInfo.signImgUrl" @click="handleView(orderInfo.signImgUrl, false)" class="img-prew" :src="orderInfo.signImgUrl" style="width: 160px; height: 120px"/>
                                    <span v-else style="color: red">未签名</span>
                                    <el-popover placement="bottom"
                                                width="400"
                                                trigger="click" @show="getSignQrUrl">
                                        <div style="width: 100%; height: 300px; padding: 30px 18px; display: flex; flex-direction: column; justify-content: space-between; align-items: center">
                                            <img :src="signQrUrl" alt="" style="width: 180px; height: 180px;">
                                            <p style="font-size: 13px; color: #666666">截屏二维码，发送给学员，微信识别签名</p>
                                            <p style="font-size: 13px; color: red">识别后，需登录小程序后进行签名</p>
<!--                                            <el-button type="text" @click="downloadSignPdf">下载签名码</el-button>-->
                                        </div>
                                        <el-button type="primary" size="mini" plain icon="el-icon-share" round slot="reference">签名码</el-button>
                                    </el-popover>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="其他信息" name="c21" v-if="orderInfo.defFieldList.length > 0">
                        <template slot="title">
                            <span class="title">其他信息</span>
                        </template>
                        <el-row :gutter="32">
                            <el-col :span="12" v-for="(item, idx) in orderInfo.defFieldList" :key="'defF' + idx">
                                <el-form-item :label="item.fieldName">
                                    {{ item.fieldValueNames}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32">
                            <el-col :span="12" v-for="(item, idx) in orderInfo.defFileList" :key="'defF' + idx">
                                <el-form-item :label="item.fieldName">
                                    <img @click="handleView(item.fileValueUrl, true, '', item.fileId, item.fileValueUrl)" class="img-prew" :src="item.fileValueUrl" style="width: 160px; height: 120px"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="银行卡信息" name="c3" v-if="orderInfo.classConfig.bankNo.needInput===1">
                        <template slot="title">
                            <span class="title">银行卡信息</span>
                        </template>
                        <el-row :gutter="32" >
                            <el-col :span="6">
                                <el-form-item label="账户姓名">
                                    {{ orderInfo.trueName }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="9">
                                <el-form-item label="开户行名称">
                                    {{orderInfo.bankName}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="9">
                                <el-form-item label="银行卡号">
                                    {{orderInfo.bankNo}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>

                    <el-collapse-item title="证书邮寄地址" name="c4" v-if="orderInfo.classConfig.certPost.needInput===1">
                        <template slot="title">
                            <span class="title">证书邮寄地址</span>
                        </template>
                        <el-row :gutter="32">
                            <el-col :span="6">
                                <el-form-item label="收件人姓名">
                                    {{ orderInfo.addrUname}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="收件人电话">
                                    {{ orderInfo.addrPhone }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="邮寄地址">
                                    {{orderInfo.addrProvinceName}}{{orderInfo.addrCityName}}{{orderInfo.addrDistrictName}}{{orderInfo.addrDesc}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="教材邮寄地址" name="c5" v-if="orderInfo.classConfig.bookPost.needInput===1">
                        <template slot="title">
                            <span class="title">教材邮寄地址</span>
                        </template>
                        <el-row :gutter="32">
                            <el-col :span="6">
                                <el-form-item label="收件人姓名">
                                    {{ orderInfo.book.linkName}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="收件人电话">
                                    {{ orderInfo.book.linkMobile }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="邮寄地址">
                                    {{orderInfo.book.provinceName}}{{orderInfo.book.cityName}}{{orderInfo.book.districtName}}{{orderInfo.book.addressDesc}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="报名方式" name="c7">
                        <template slot="title">
                            <span class="title">报名方式</span>
                        </template>
                        <el-row :gutter="16">
                            <el-col :span="12">
                                <el-form-item label="报名方式">
                                    {{orderInfo.insteadEnroll === 0 ? '本人报名' : '代他人报名'}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="报名费用状态" name="c6">
                        <template slot="title">
                            <span class="title">报名费用状态</span>
                        </template>
                        <el-row :gutter="16">
                            <el-col :span="4">
                                <el-form-item label="报名金额">
                                    {{orderInfo.priceValue}}元
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label="支付金额">
                                    {{orderInfo.payValue}}元
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label="支付方式">
                                    {{orderInfo.payWay === 1 ? '微信支付' : orderInfo.payWay === 2 ? '支付宝' : '线下缴费'}}
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label="支付状态">
                                    <el-tag :type="orderInfo.payStatus | colorFilter2">
                                        {{ orderInfo.payStatus  | payStatusFilter}}
                                    </el-tag>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label="审核状态">
                                    <el-tag :type="orderInfo.confirmStatus | colorFilter2">
                                        {{ orderInfo.confirmStatus  | confirmStatusFilter}}
                                    </el-tag>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>
        </div>
        <div class="demo-drawer__footer">
            <el-button type="primary" plain v-show="hasPerm('enroll:confirm') && orderInfo.payStatus === 1 && orderInfo.confirmStatus === 1 && orderInfo.takeExam === 1" @click="updTakeExam(orderInfo.orderId, 0)">取消考试资格</el-button>
            <el-button type="primary" plain v-show="hasPerm('enroll:confirm') && orderInfo.payStatus === 1 && orderInfo.confirmStatus === 1 && orderInfo.takeExam === 0" @click="updTakeExam(orderInfo.orderId, 1)">设置考试资格</el-button>

            <el-button @click="openDrawer = false">关闭</el-button>

            <el-button v-if="hasPerm('enroll:edit') && orderInfo.confirmStatus === 0" style="margin-right: 8px" @click="showEditor(orderInfo.orderId)">修改</el-button>
            <el-button v-show="hasPerm('enroll:confirm:finance') && orderInfo.payStatus === 0 && orderInfo.deleteStatus === 0" style="margin-right: 8px" type="primary" @click="toConfirmPay(orderInfo.orderId, orderInfo.priceValue, orderInfo.payValue)">确认支付</el-button>
            <el-button type="danger" plain v-show="hasPerm('enroll:confirm') && orderInfo.payStatus === 1 && orderInfo.confirmStatus === 0 && orderInfo.deleteStatus === 0" @click="toRemind(orderInfo.userId, orderInfo.orderId)">资料完善提醒</el-button>
            <el-button type="primary" plain v-show="hasPerm('enroll:confirm') && orderInfo.payStatus === 1 && orderInfo.confirmStatus === 0 && orderInfo.deleteStatus === 0" @click="toRefund(orderInfo.orderId, orderInfo.payValue)">申请退款</el-button>
            <el-button type="primary" plain v-show="hasPerm('enroll:confirm') && orderInfo.payStatus === 1 && orderInfo.confirmStatus === 0 && orderInfo.deleteStatus === 0" @click="toConfirm(orderInfo.orderId, 1)">确认审核</el-button>
            <el-button type="primary" plain v-show="hasPerm('enroll:confirm') && orderInfo.payStatus === 1 && orderInfo.confirmStatus === 1" @click="toConfirm(orderInfo.orderId, 0)">取消审核</el-button>
        </div>
    </el-drawer>
    <!-- 证件照片预览对话框 -->
    <el-dialog
        v-dialogDrag
        title="预览"
        :visible.sync="imgVisible"
        :append-to-body="true"
        :destroy-on-close="false"
        :close-on-click-modal="false"
        width="60%">
        <img :src="fileUrl" v-if="imgVisible" style="width: 100%;">
        <div slot="footer" class="dialog-footer">
            <el-button v-show="imgUpdateInfo.canCut" style="margin-left: 20px" type="primary" @click="toEditorImg()">编辑</el-button>
            <el-button type="text" @click="imgVisible=false">关闭</el-button>
        </div>
    </el-dialog>
    <ImgCropperDiag :show="showImgCropper"
                    @callback="hideImgCropper"
                    @upload="manualUpload"
                    :option="cropperOption"
                    :scaleOption = "cropperScaleOption"
                    >
    </ImgCropperDiag>

        <!-- 确认支付对话框 -->
        <el-dialog
            title="确认支付"
            :append-to-body="true"
            :visible.sync="confirmModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="confirmInfo"  ref="confirmForm" label-width="150px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="实际支付金额" prop="priceValue">
                            <el-input v-model="confirmInfo.payValue" placeholder="请输入实际支付金额"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmModal = false">取消</el-button>
                <el-button type="primary" @click="confirmPay('confirmForm')">确认支付</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="退款申请"
            :append-to-body="true"
            :visible.sync="refundModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="refundInfo" :rules="refundValidate"  ref="refundForm" label-width="150px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="退款金额" prop="refundValue">
                            {{refundInfo.refundValue}}元
                        </el-form-item>
                        <el-form-item label="退款原因" prop="refundReason">
                            <el-input type="textarea" v-model="refundInfo.refundReason" placeholder="请输入退款原因"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="refundModal = false">取消</el-button>
                <el-button type="primary" @click="confirmRefund('refundForm')">确认支付</el-button>
            </span>
        </el-dialog>
        <!-- 资料完善提醒对话框 -->
        <el-dialog
            title="资料完善提醒"
            :visible.sync="remindModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="remindInfo" :rules="ruleRemindValidate" ref="remindForm" label-width="150px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="信息完善内容" prop="content">
                            <el-input type="textarea" v-model="remindInfo.content" placeholder="请输入信息完善内容（最多100个汉字）"></el-input>
                        </el-form-item>
                        <el-form-item label="其他说明" prop="remark">
                            <el-input type="textarea" v-model="remindInfo.remark" placeholder="请输入备注说明（最多100个汉字）"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="remindModal = false">取消</el-button>
                <el-button type="primary" @click="remindSave('remindForm')">确认保存</el-button>
            </span>
        </el-dialog>
        <!-- 确认审核对话框 -->
        <el-dialog
            title="审核确认"
            :visible.sync="postModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="postInfo" :rules="rulePostValidate" ref="postForm" label-width="150px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="是否邮寄教材" prop="postBook">
                            <el-radio-group v-model="postInfo.postBook">
                                <el-radio :label="1">邮寄</el-radio>
                                <el-radio :label="2">不邮寄</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="postModal = false">取消</el-button>
                <el-button type="primary" @click="confirm('postForm')">确认审核</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import ImgCropperDiag from '../../../components/upload/ImgCropperDiag';
export default {
    components: {
        ImgCropperDiag,
    },
    filters: {
        dataStatusFilter(status) {
            const statusMap = {
                1: '已提交',
                0: '暂存',
            };
            return statusMap[status]
        },
        deleteStatusFilter(status) {
            const statusMap = {
                1: '已删除',
                0: '正常',
            };
            return statusMap[status]
        },
        confirmStatusFilter(status) {
            const statusMap = {
                1: '已审核',
                0: '未审核',
            };
            return statusMap[status]
        },
        payStatusFilter(status) {
            const statusMap = {
                1: '已支付',
                0: '未支付',
                2: '退款中',
                3: '已退款',
            };
            return statusMap[status]
        },
        payWayFilter(status) {
            const statusMap = {
                1: '微信支付',
                2: '支付宝',
                3: '线下支付'
            };
            return statusMap[status]
        },
        enrollTypeFilter(status) {
            const statusMap = {
                'SELF': '个人',
                'COMPANY': '公司',
            };
            return statusMap[status]
        },
        classTypeFilter(status) {
            const statusMap = {
                'LEARN': '继续教育',
                'ENROLL': '考试报名',
            };
            return statusMap[status]
        },
        postBookFilter(status) {
            const statusMap = {
                1: '是',
                2: '否'
            };
            return statusMap[status]
        },
        sexFilter(status) {
            const statusMap = {
                1: '男',
                2: '女'
            };
            return statusMap[status]
        },
        taskTypeFilter(status) {
            const statusMap = {
                '1000': '开放任务',
                '2000': '内部任务'
            };
            return statusMap[status]
        },
        consumTypeFilter(status) {
            const statusMap = {
                1: '单人领取',
                2: '多人领取'
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger',
            };
            return statusMap[status]
        },
        colorFilter2(status) {
            const statusMap = {
                0: 'danger',
                1: 'success',
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            openDrawer: false,
            orderId: '',
            orderInfo: {
                classConfig: {
                    sex: {}
                },
                defFieldList: [],
                defFileList: [],
                headImgBgcolor: '蓝底'
            },
            imgVisible: false,
            fileUrl: '',
            imgUpdateInfo: {
                canCut: false,
                fileUrl: '',
                fieldName: '',
                filePath: '',
                fileId: '',
                orderId: ''
            },
            activeNames: ["c1", "c2", "c21", "c3", "c4", "c5", "c6", "c7"],
            showImgCropper: false,
            cropperOption: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: false, // 是否开启截图框宽高固定比例
                fixedNumber: [3, 2], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: false, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: false, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                autoCropWidth: 600, // 默认生成截图框宽度
                autoCropHeight: 375, // 默认生成截图框高度
                enlarge: 2,
            },
            cropperScaleOption: [],
            confirmModal: false,
            confirmInfo: {
                priceValue: '',
            },
            refundModal: false,
            refundInfo: {
                orderId: '',
                refundReason: '',
                refundValue: ''
            },
            remindInfo: {},
            remindModal: false,
            ruleRemindValidate: {
                content: [
                    { required: true, message: '请输入信息完善内容', trigger: 'blur' }
                ],
            },
            refundValidate: {
                refundValue: [
                    { required: true, message: '退款金额', trigger: 'blur' }
                ],
                refundReason: [
                    { required: true, message: '请输入退款原因', trigger: 'blur' }
                ],
            },
            postModal: false,
            rulePostValidate: {
                postBook: [
                    { required: true, type: 'number', message: '请选择是否邮寄教材', trigger: 'change' }
                ],
            },
            postInfo: {},
            signQrUrl: '',
        }
    },
    methods: {
        show: function (orderId) {
            this.openDrawer = true;
            this.orderId = orderId;
            this.getById();
            this.$nextTick(function () {
                window.scroll(0, 0);
            });
        },
        getById: function() {
            this.apis.get('user/enrollOrder/getById?orderId=' + this.orderId)
                .then((res) => {
                    if (res.code === 1) {
                        this.orderInfo = res.data;
                    } else {
                        this.orderInfo = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        /**
         * handleView(orderInfo.certHeadImgUrl, false, 'cert_head_img', '', orderInfo.certHeadImgOrgUrl)
         * 报名信息Drawer>照片预览
         */
        handleView (fileUrl, canCut, fieldName, fileId, fileOrgUrl) {
            this.fileUrl = fileUrl;
            this.imgUpdateInfo.canCut = canCut;
            this.imgUpdateInfo.fieldName = fieldName;
            this.imgUpdateInfo.fileId = fileId;
            this.imgUpdateInfo.orderId = this.orderInfo.orderId;
            this.imgUpdateInfo.fileUrl = fileOrgUrl;
            this.imgVisible = true;
        },
        setCroperScaleOption(fieldName) {
            if (fieldName === 'cert_head_img') {
                this.cropperScaleOption = [
                    { name: '1寸', width: 295, height: 413 },
                    { name: '小2寸', width: 390, height: 567 },
                    { name: '2寸', width: 413, height: 626 },
                ]
            } else if (fieldName === 'id_card_front' || fieldName === 'id_card_back') {
                this.cropperScaleOption = [
                    { name: '身份证', width: 600, height: 375 },
                ]
            } else {
                this.cropperScaleOption = []
            }
        },
        toEditorImg: function () {
            let _this = this;
            _this.setCroperScaleOption(this.imgUpdateInfo.fieldName);
            // console.log('aaa');
            _this.imageUrlToBase64(this.imgUpdateInfo.fileUrl);
            // this.$forceUpdate()
            // this.toEdit(this.currentOrderId)
        },
        hideImgCropper: function () {
            this.showImgCropper = false;
            // this.toEdit(this.currentOrderId)
        },
        //异步执行
        imageUrlToBase64(url) {
            if (!url) {
                // console.log("url不存在");
                return
            }
            this.cropperOption.img = url;
            this.showImgCropper = true;
            this.imgVisible = false;
        },
        imageUrlToBase642(url) {
            if (!url) {
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            //一定要设置为let，不然图片不显示
            let image = new Image();
            //解决跨域问题
            //  image.setAttribute('crossOrigin', '*');
            //解决跨域问题
            image.setAttribute('crossOrigin', 'anonymous');
            // image.crossOrigin = true;
            // console.log(image.crossOrigin);
            image.src = url;
            try {
                //image.onload为异步加载
                image.onload = () => {
                    let canvas = document.createElement("canvas");
                    // console.log(image.width)
                    canvas.width = image.width;
                    canvas.height = image.height;
                    let context = canvas.getContext('2d');
                    context.drawImage(image, 0, 0, image.width, image.height);
                    let quality = 1;
                    //这里的dataurl就是base64类型
                    let dataURL = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
                    this.cropperOption.img = dataURL;
                    this.showImgCropper = true;
                    this.imgVisible = false;
                }
            } catch (error) {
                console.log(error)
                this.$message({
                    message: error.message,
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
            } finally {
                loading.close();
            }
        },
        manualUpload (file) {
            let data = {
                file: file
            };
            this.apis.postForm('system/file/uploadFileBase64', data).then((res) => {
                if (res.code === 1) {
                    // this.imageInfo.imgUrl = res.data.webPath;
                    // this.imageInfo.img = res.data.savepath;

                    this.imgUpdateInfo.filePath = res.data.savepath;
                    this.updateImg(this.imgUpdateInfo)
                    this.showImgCropper = false;
                } else {
                    this.$message({
                        message: res.msg,
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                }
            }).catch((err) => {
                console.log(err)
            });
        },
        updateImg: function (data) {
            this.apis.postForm('user/enrollOrder/updateImg', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '保存成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.getById(data.orderId);
                        // this.initTable();
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        showEditor: function (orderId) {
            this.$emit("showEditor", orderId)
            this.openDrawer = false;
        },
        //报名信息Drawer>确认支付
        toConfirmPay: function (orderId, priceValue, payValue) {
            this.confirmInfo = {
                orderId: '',
                payValue: ''
            };
            this.confirmInfo.orderId = orderId;
            this.confirmInfo.priceValue = priceValue;
            if (payValue !== undefined && payValue !== null && payValue !== '') {
                this.confirmInfo.payValue = payValue;
            } else {
                this.confirmInfo.payValue = priceValue;
            }
            this.confirmModal = true;
        },
        toRefund: function (orderId, payValue) {
            this.refundInfo.orderId = orderId;
            this.refundInfo.refundValue = payValue;
            this.refundInfo.refundReason = ''
            this.refundModal = true;
        },
        confirmRefund: async function (formRef) {
            if (this.refundInfo.refundValue <=0) {
                this.$message({
                    message: "金额小于0，不需进行退款",
                    showClose: true, offset: 50, type: 'error'
                });
                return;
            }
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let title = '退款金额：' + this.refundInfo.refundValue + '元';
                    this.$confirm(title, '确定进行退款？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {

                        this.refundInfo.applyUser = this.appCookies.getCookieTNAME();
                        this.refundInfo.createBy = this.appCookies.getCookieUID();
                        this.refundInfo.createByname = this.appCookies.getCookieUNAME();
                        this.apis.postJson('user/refund/save', this.refundInfo)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '提交成功，请等待财务进行确认审核',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.refundModal = false;
                                    this.getById();
                                    this.$emit("refreshTable");
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }).catch(() => {
                    });
                }
            })
        },
        //确认支付对话框>确认支付
        confirmPay: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let title = '支付金额：' + this.confirmInfo.payValue + '元';
                    this.$confirm(title, '确定此报名记录已支付完成？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let data = {
                            orderId: this.confirmInfo.orderId,
                            payValue: this.confirmInfo.payValue,
                            operUserId: this.appCookies.getCookieUID(),
                            operUserName: this.appCookies.getCookieUNAME()
                        };
                        this.apis.postForm('user/enrollOrder/updatePayValue', data)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.confirmModal = false;
                                    this.getById();
                                    this.$emit("refreshTable");
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }).catch(() => {
                    });
                }
            })
        },
        //报名信息Drawer>资料完善提醒
        toRemind: function (userId, orderId) {
            this.remindInfo = {};
            this.remindInfo.orderId = orderId;
            this.remindInfo.userId = userId;
            this.remindModal = true;
        },
        //报名信息Drawer>资料完善提醒>信息完善dialog>确认保存
        remindSave: function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let title = '确定发送提醒信息？';
                    this.$confirm(title, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.apis.postJson('user/enrollOrder/infoAdd', this.remindInfo)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '发送成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.remindModal = false;
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }).catch(() => {
                    });
                }
            });
        },
        updTakeExam: function (orderId, takeExam) {
            let msg = '确定要取消考试资格？'
            if (takeExam === 1) {
                msg = '确定要设置可参加考试？'
            }
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    orderId: orderId,
                    takeExam: takeExam,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('user/enrollOrder/updTakeExam', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.postModal = false;
                            this.getById();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        //报名信息Drawer>确认审核
        toConfirm: function (orderId, confirmStatus) {
            if (confirmStatus===1) {
                if (this.orderInfo.classConfig.certPost.needInput===1) {
                    this.postInfo = {};
                    this.postInfo.orderId = orderId;
                    this.postModal = true;
                } else {
                    this.postInfo = {};
                    this.postInfo.orderId = orderId;
                    this.postInfo.postBook = 2;
                    this.submitConfirm();
                }
            } else {
                this.$confirm('确定要取消审核？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        orderId: orderId,
                        confirmStatus: 0,
                        postBook: this.postInfo.postBook,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('user/enrollOrder/unconfirm', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '审核成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.postModal = false;
                                this.getById();
                                this.$emit("refreshTable");
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            }
        },
        //确认审核dialog>确认审核
        confirm: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    this.submitConfirm();
                }
            })
        },
        submitConfirm: function () {
            if (this.orderInfo.payStatus === 0) {
                this.$message({
                    message: '未支付不能进行审核，请先确认支付',
                    showClose: true, offset: 200, type: 'success'
                });
                return;
            }
            this.$confirm('确定要审核此报名记录？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    orderId: this.postInfo.orderId,
                    confirmStatus: 1,
                    postBook: this.postInfo.postBook,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('user/enrollOrder/confirm', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.postModal = false;
                            this.getById();
                            this.$emit("refreshTable");
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        getSignQrUrl: function () {
            if (this.orderInfo.orderId) {
                this.signQrUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=QIANMING&bizId=' + this.orderInfo.orderId + "&tenantId=" + this.appCookies.getTenantId();
            } else {
                this.signQrUrl = ''
            }
        },
        downloadSignPdf: function () {
            let url = 'course/class/exam/sched/downloadSigninQr'
            let data = {
                classExamId: this.queryParams.classExamId,
                schedId: this.queryParams.schedId
            }
            this.downloadPdf(url, data)
        },
        downloadPdf: function (url, data) {
            // let data = {
            //     trueName: this.info.trueName,
            //     idCard: this.info.idCard,
            //     workName: '',
            //     workYear: this.info.workYears,
            // }
            this.apis.axios()({
                method: 'post',
                url: url,
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '未查询到数据',
                        showClose: true, offset: 50, type: 'error'
                    });
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '.pdf')
            }).catch(err => {
                console.log(err);
            });
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog{
    margin-top: 5vh!important;
}
/deep/ .el-dialog__body{
    height: 70vh;
    padding: 0 10px;
}
</style>
