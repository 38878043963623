<template>
    <div class="uploadmain">
        <el-upload
            class="image-uploader"
            :headers="{'Authorization': accessToken}"
            accept=".png,.jpg,.jpeg"
            action="tmp"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
            :on-progress="uploadProcess"
            :on-preview="handlePictureCardPreview">
            <img v-if="uploadFlag===false && currImageUrl" :src="currImageUrl" class="image" :style="{width: width+ 'px', height: height+ 'px'}">
            <el-progress v-else-if="currImageUrl==='' && uploadFlag == true" type="circle" :percentage="uploadPercent"></el-progress>
            <i v-else class="el-icon-plus image-uploader-icon" :style="{width: width+ 'px', height: height+ 'px', lineHeight: height+ 'px'}"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="currImageUrl" alt="">
        </el-dialog>
        <div style="text-align: center">
            <p style="color: red; line-height: 16px">{{text}}</p>
            <p v-show="subtext" style="color: red; line-height: 16px">{{subtext}}</p>
        </div>
        <ImgCropperDiag :show="showImgCropper"
            @callback="hideImgCropper"
            @upload="manualUpload"
            :option="cropperOption">
        </ImgCropperDiag>
    </div>
</template>

<script>
    import Compressor from "compressorjs";
    import ImgCropperDiag from './ImgCropperDiag';
    export default {
        components: {
            ImgCropperDiag,
        },
        props: {
            height: {
                type: Number,
                default: 140
            },
            width: {
                type: Number,
                default: 140
            },
            imageUrl: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            subtext: {
                type: String,
                default: ''
            },
            cropper: {
                type: Boolean,
                default: false
            },
            autoCropWidth: {
                type: Number,
                default: 300
            },
            autoCropHeight: {
                type: Number,
                default: 200
            },
        },
        watch: {
            imageUrl: {
                handler (val) {
                    this.currImageUrl = val
                },
                deep: true,
                immediate: true
            },
            autoCropWidth: {
                handler (val) {
                    if (val) {
                        this.cropperOption.autoCropWidth = val
                    }
                },
                deep: true,
                immediate: true
            },
            autoCropHeight: {
                handler (val) {
                    if (val) {
                        this.cropperOption.autoCropHeight = val
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
                uploadUrl: process.env.VUE_APP_API_HOST + 'system/file/uploadFile',
                dialogVisible: false,
                uploadFlag: false,
                uploadPercent: 0,
                showImgCropper: false,
                cropperOption: {
                    img: '', // 裁剪图片的地址
                    info: true, // 裁剪框的大小信息
                    outputSize: 0.8, // 裁剪生成图片的质量
                    outputType: 'png', // 裁剪生成图片的格式
                    canScale: true, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    autoCropWidth: 300, // 默认生成截图框宽度
                    autoCropHeight: 200, // 默认生成截图框高度
                    fixedBox: false, // 固定截图框大小 不允许改变
                    fixed: false, // 是否开启截图框宽高固定比例
                    fixedNumber: [3, 2], // 截图框的宽高比例
                    full: true, // 是否输出原图比例的截图
                    canMoveBox: true, // 截图框能否拖动
                    original: false, // 上传图片按照原始比例渲染
                    centerBox: false, // 截图框是否被限制在图片里面
                    infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                },
                currImageUrl: '',
            }
        },
        methods: {
            uploadFile: function (f) {
                let that = this;
                // eslint-disable-next-line no-new
                new Compressor(f.file, {
                    maxWidth: 1024,   // 压缩完成的宽度，高度会自适应
                    quality: 0.8, // 压缩的质量，不加上面的宽度话压缩效果很不理想，体积基本没有变化，一定要加个宽或者高度
                    success: res => {      // 箭头函数的形势去除success this指向内部函数的
                        // file = new File([res], res.name, {  // 返回的结果是个blob对象，js内置函数将其3转为file对象
                        //     type: res.type,
                        //     lastModified: Date.now()
                        // });
                        // console.log("图片压缩后res", res)
                        // console.log("图片压缩后file", file.size)
                        let reader = new FileReader();
                        reader.readAsDataURL(res);
                        reader.onload = function (e) {
                            // e.target.result
                            console.log("e.target.result", e.target.result)
                            let fdata = {
                                file: e.target.result
                            }
                            let url = "system/file/uploadFileBase64";
                            // 保存数据
                            that.apis.postForm(url, fdata)
                                .then((res) => {
                                    if (res.code === 1) {
                                        console.log(res.data)
                                        that.success(res.data);
                                    } else {
                                        this.$message({
                                            message: res.msg,
                                            showClose: true,
                                            offset: 200,
                                            type: 'error'
                                        });
                                    }
                                }).catch(err => {
                                console.log(err);
                            });
                        }
                    },
                    error (err) {
                        that.$message({ message: '上传失败', showClose: true, offset: 200, type: 'error' });
                        console.log(err)
                    }
                });

                // let formData = new FormData();
                // formData.append('file', f.file);
                // let url = "system/file/uploadFile";
                // // 保存数据
                // this.apis.postFile(url, formData)
                //     .then((res) => {
                //         if (res.code === 1) {
                //             console.log(res.data)
                //             this.success(res.data);
                //         } else {
                //             this.$message({
                //                 message: res.msg,
                //                 showClose: true,
                //                 offset: 200,
                //                 type: 'error'
                //             });
                //         }
                //     }).catch(err => {
                //     console.log(err);
                // });
            },
            uploadSuccess(res, file) {
                // console.log(res) savepath
                this.success(res.data);
            },
            success(data) {
                // console.log(res) savepath
                this.uploadFlag = false;
                this.uploadPercent = 0;
                this.currImageUrl = data.webPath;
                this.$emit("callback", data.webPath, data.savepath)
            },
            beforeUpload(file) {

                let types = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png'];
                const isImage = types.includes(file.type);
                const isLt2M = true; // file.size / 1024 / 1024 < 2;
                if (!isImage) {
                    this.$message({
                        message: '只能上传图片格式!',
                        showClose: true,
                        offset: 150,
                        type: 'error'
                    });
                }
                if (this.cropper) {
                    this.fileinfo = file;
                    let data = window.URL.createObjectURL(new Blob([file]));
                    this.cropperOption.img = data;
                    // console.log(this.cropperOption.img);
                    this.showImgCropper = true;
                    return false; // 取消自动上传
                }
                // if (!isLt2M) {
                //     this.$message({
                //         message: '上传头像图片大小不能超过 2MB!',
                //         showClose: true,
                //         offset: 150,
                //         type: 'error'
                //     });
                // }
                return isImage && isLt2M;
            },
            uploadProcess(event, file, fileList){
                this.uploadFlag = true;
                this.uploadPercent = parseInt(file.percentage.toFixed(0), 0);
            },
            handlePictureCardPreview(file) {
                this.dialogVisible = true;
            },
            hideImgCropper: function () {
                this.showImgCropper = false;
            },
            manualUpload (file) {
                let data = {
                    file: file
                };
                this.apis.postForm('system/file/uploadFileBase64', data).then((res) => {
                    if (res.code === 1) {
                        // this.imageInfo.imgUrl = res.data.webPath;
                        // this.imageInfo.img = res.data.savepath;
                        this.showImgCropper = false;
                        this.$forceUpdate();
                        this.success(res.data);
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
        },
        mounted() {
            this.$nextTick(function () {
            });
        }
    }
</script>

<style lang="scss" scoped>
    .uploadmain {
        height: 100%;
        width: 100%;
    }
    ::v-deep .image-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
        .image-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 140px;
            height: 140px;
            line-height: 140px;
            text-align: center;
        }
        .image {
            width: 140px;
            height: 140px;
            display: block;
        }
    }
</style>

