<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col>
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>证书列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="关键字">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:140px"></el-input>
                                </el-form-item>
                                <el-form-item label="审核状态">
                                    <el-select v-model="queryParams.confirmStatus" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="未审核" value="0"></el-option>
                                        <el-option label="已审核" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <div style="float: right">
                                    <el-link href="../../CERTImportModelv1.xlsx" target="_blank" style="margin-right: 10px">下载模板</el-link>
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toImport">导入</el-button>
                                </div>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column
                                    label="#"
                                    type="index"
                                    width="50">
                                </el-table-column>
                                <el-table-column label="证书类别" prop="categoryName" min-width="150px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种名称" prop="gnvqName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="证书级别" prop="levelName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="鉴定机构" prop="identifyOrgName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="鉴定日期" prop="identifyDate" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="鉴定批次" prop="identifyBatch" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="审核状态" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.confirmStatus | colorFilter2">
                                            {{ row.confirmStatus  | confirmStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="删除状态" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="导入时间" prop="createTime" min-width="170px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作人" prop="modifyByname" min-width="130px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="180" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus === 0 && row.confirmStatus === 0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.importId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus === 0 && row.confirmStatus === 0" type="text" size="mini" @click="toConfirm(row.importId, 1)">审核</el-button>
                                        <el-button v-if="row.deleteStatus === 0 && row.confirmStatus === 1" type="text" size="mini" @click="toConfirm(row.importId, 0)">取消审核</el-button>
                                        <el-button type="text" size="mini" @click="showDetail(row.importId)">明细信息</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 证书列表 -->
        <el-drawer
            title="证书列表"
            :visible.sync="detailDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="detailDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <!-- 搜索查询 -->
                <el-row class="query-container">
                    <el-col :span="24">
                        <el-form :inline="true">
                            <el-form-item label="关键字">
                                <el-input v-model="subQueryParams.keyword" placeholder="查询关键字" style="width:140px"></el-input>
                            </el-form-item>
                            <el-form-item label="邮寄状态">
                                <el-select v-model="subQueryParams.postStatus" placeholder="请选择"  style="width:100px">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="未邮寄" value="0"></el-option>
                                    <el-option label="已邮寄" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="接收状态">
                                <el-select v-model="subQueryParams.receiveStatus" placeholder="请选择" style="width:100px">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="未确认" value="0"></el-option>
                                    <el-option label="已确认" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search"  @click="refreshTable2">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
                <!-- 列表内容 -->
                <el-row :gutter="32" class="table-container">
                    <el-col>
                        <el-table
                            :data="scoreData"
                            fit
                            stripe
                            highlight-current-row
                            style="width: 100%;"
                            ref="queryTable"
                            :indent="20"
                        >
                            <el-table-column label="#" type="index" width="50px"></el-table-column>
                            <el-table-column label="编号" prop="scoreCode" width="50px" align="left" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="姓名" prop="trueName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="身份证号" prop="idCard" min-width="120px"></el-table-column>
                            <el-table-column label="工种名称" prop="gnvqName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="证书级别" prop="levelName" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="证书编号" prop="certCode" min-width="120px"></el-table-column>
                            <el-table-column label="证书有效期" prop="certPeriod" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="电话" prop="phone" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="邮寄地址" min-width="200px">
                                <template slot-scope="{ row }">
                                    <span>{{row.addrProvinceName + row.addrCityName + row.addrDistrictName + row.addrDesc}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="邮寄状态" prop="postStatus" min-width="120px" :show-overflow-tooltip="true">
                                <template slot-scope="{row}">
                                    <el-tag :type="row.postStatus | colorFilter2">
                                        {{ row.postStatus  | postStatusFilter }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="快递名称" prop="expressName" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="快递单号" prop="expressNo" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="学员确认" prop="receiveStatus" min-width="120px" :show-overflow-tooltip="true">
                                <template slot-scope="{row}">
                                    <el-tag :type="row.receiveStatus | colorFilter2">
                                        {{ row.receiveStatus  | receiveStatusFilter}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding fixed-width">
                                <template slot-scope="{row}">
                                    <el-button plain size="mini" @click="toPost(row.scoreId, row.expressName, row.expressNo)">确认邮寄</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 分页组件 -->
                        <Pagination v-show="subQueryParams.totalRecord>0" :total="subQueryParams.totalRecord"
                                    :page.sync="subQueryParams.pageNumber" :limit.sync="subQueryParams.pageSize"
                                    @pagination="getDetail"/>
                    </el-col>
                </el-row>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="detailDrawer = false">关闭</el-button>
            </div>
        </el-drawer>
        <el-dialog
            title="证书导入"
            :visible.sync="editImportDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="22">
                        <el-form-item label="工种分类" prop="categoryId">
                            <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.categoryId" :gnvqId.sync="info.gnvqId"
                                        :levelId.sync="info.levelId"  @callback="levelChange"></GnvqSelect>
                        </el-form-item>
                        <el-form-item label="鉴定机构" prop="identifyOrgId">
                            <el-select filterable v-model="info.identifyOrgId">
                                <el-option label="请选择鉴定机构" value=""></el-option>
                                <el-option v-for="item in identifyOrgList" :label="item.dictName" :value="item.dictId" :key="item.dictId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报考日期" prop="identifyDate">
                            <el-date-picker
                                v-model="info.identifyDate"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="报考批次" prop="identifyBatch">
                            <el-input v-model="info.identifyBatch" placeholder="请输入报考批次" />
                        </el-form-item>
                        <el-form-item label="上传文件">
                            <el-upload
                                class="upload-demo"
                                accept=".xls,.xlsx"
                                ref="upload"
                                :action="getUploadUrl()"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :on-change="fileChange"
                                :file-list="fileList"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx文件</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editImportDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="邮寄信息"
            :visible.sync="editPostDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="postInfo" :rules="postRuleValidate" ref="postForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="快递名称" prop="expressName">
                            <el-input v-model="postInfo.expressName" placeholder="请输入快递名称"></el-input>
                        </el-form-item>
                        <el-form-item label="快递单号" prop="expressNo">
                            <el-input v-model="postInfo.expressNo" placeholder="请输入快递单号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editPostDialog = false">关 闭</el-button>
                <el-button type="primary" @click="savePost('postForm')">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqSelect from "@v/components/GnvqSelect";
    //import SingleUploadExcelFile from "@c/upload/SingleUploadExcelFile";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqSelect,
            //SingleUploadExcelFile,
        },
        filters: {
            confirmStatusFilter(status) {
                const statusMap = {
                    0: '未审核',
                    1: '已审核',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            postStatusFilter(status) {
                const statusMap = {
                    0: '未邮寄',
                    1: '已邮寄',
                };
                return statusMap[status]
            },
            receiveStatusFilter(status) {
                const statusMap = {
                    0: '未确认',
                    1: '已确认'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
            colorFilter2(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success',
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '证书导入'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    locakStatus: '0',
                    keyword: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //导入证书表单
                editImportDialog: false,
                file: null,
                fileList: [],
                //鉴定机构数据
                identifyOrgList: [],
                info: {
                    importId: ''
                },
                //表单验证规则
                ruleValidate: {
                    categoryId: [
                        { required: true, type: 'number', message: '请选择工种分类', trigger: 'blur' }
                    ],
                    gnvqId: [
                        { required: true, type: 'number', message: '请选择工种', trigger: 'blur' }
                    ],
                    // levelId: [
                    //     { required: true, type: 'number', message: '请选择工种等级', trigger: 'blur' }
                    // ],
                    identifyOrgId: [
                        { required: true, type: 'number', message: '请选择鉴定机构', trigger: 'change' }
                    ],
                    // identifyDate: [
                    //     { required: true, message: '请选择报考日期', trigger: 'change' }
                    // ],
                    identifyBatch: [
                        { required: true, message: '请输入报考批次', trigger: 'blur' }
                    ],
                },
                //证书明细列表
                detailDrawer: false,
                //证书明细列表数据
                scoreData: [],
                //证书明细查询数据
                subQueryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    deleteStatus: '0',
                    postStatus: '',
                    receiveStatus: '',
                    orgId: this.appCookies.getCookieOID(),
                },
                //证书明细邮寄信息表单编辑
                editPostDialog: false,
                postInfo: {},
                postRuleValidate: {
                    expressName: [
                        { required: true, message: '请输入快递名称', trigger: 'blur' }
                    ],
                    expressNo: [
                        { required: true, message: '请输入快递单号', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
        },
        methods: {
            //导入
            toImport: function () {
                this.info = {
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    importId: ''
                };
                this.file = null;
                this.editImportDialog = true;
                this.getIdentifyOrgList()
            },
            beforeUpload(file) {
                //文件类型
                const isType = file.type === 'application/vnd.ms-excel'
                const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                const fileType = isType || isTypeComputer
                if (!fileType) {
                    this.$message.error('上传文件只能是xls/xlsx格式！')
                }
                this.file = file;
                this.info.file = file;
                return fileType;
            },
            fileChange(file, fileList) {
                console.log(file)
                this.file = file.raw
            },
            getUploadUrl: function () {
                return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
            },
            uploadSuccess(res, file) {
                // console.log(res) savepath
                this.success(res.data);
            },
            success(data) {
                // console.log(res) savepath
                this.uploadFlag = false;
                this.uploadPercent = 0;
                this.imageUrl = data.webPath;
                this.$emit("callback", data.webPath, data.savepath)
            },
            toAdd: function () {
                this.info = {
                    importId: ''
                };
                this.file = null;
                this.editImportDialog = true;
                this.getIdentifyOrgList()
            },
            getIdentifyOrgList: function () {
                this.apis.get('system/dict/getList?typeCode=0006')
                    .then((res) => {
                        if (res.code === 1) {
                            this.identifyOrgList = res.data || []
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('user/resultScoreImport/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        importId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('user/resultScoreImport/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            //审核
            toConfirm: function (importId, flag) {
                let title = '确定要审核此条数据？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        importId: importId,
                        confirmStatus: flag,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('user/resultScoreImport/confirm', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            showDetail: function (importId) {
                this.subQueryParams.importId = importId;
                this.refreshTable2()
            },
            //获取明细列表数据
            getDetail: function () {
                if (this.subQueryParams.postStatus === undefined) {
                    this.subQueryParams.postStatus = '';
                }
                if (this.subQueryParams.receiveStatus === undefined) {
                    this.subQueryParams.receiveStatus = '';
                }
                this.tableLoading = true;
                this.apis.postForm('user/resultScore/query', this.subQueryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.scoreData = res.data;
                            this.subQueryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                            this.detailDrawer = true;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable2: function () {
                this.subQueryParams.pageNumber = 0;
                this.getDetail();
            },
            //gnvqSelect组件（工种等级分类）callback方法
            levelChange: function (levelInfo) {
                console.log(levelInfo)
            },
            //确认邮寄
            toPost: async function (scoreId, expressName, expressNo) {
                this.postInfo = {
                    expressName: '',
                    expressNo: ''
                };
                this.postInfo.scoreId = scoreId;
                if (expressName) {
                    this.postInfo.expressName = expressName;
                    this.postInfo.expressNo = expressNo;
                }
                this.editPostDialog = true;
            },
            //保存邮寄信息
            savePost: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "user/resultScore/updPostStatus";
                        this.postInfo.postStatus = 1;
                        this.postInfo.operUserId = this.appCookies.getCookieUID();
                        this.postInfo.operUserName = this.appCookies.getCookieUNAME();
                        // 保存数据
                        this.apis.postForm(url, this.postInfo)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editPostDialog = false;
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.getDetail();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {

                        let formData = new FormData();
                        formData.append('file', this.file);

                        let url = "user/resultScoreImport/import";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;

                        formData.append("info", new Blob([JSON.stringify(this.info)], { type: "application/json" }));
                        console.log(formData)
                        // 保存数据
                        this.apis.postFile(url, formData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editImportDialog = false;
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                                console.log(err);
                        });
                    }
                })
            },
            // saveData: async function (formRef) {
            //     this.$refs[formRef].validate((valid) => {
            //     console.log(this.$refs)
            //     if (valid) {
            //         let url = "xxx/port";
            //         this.info.createBy = this.appCookies.getCookieUID();
            //         this.info.createByname = this.appCookies.getCookieTNAME();
            //         this.info.modifyBy = this.appCookies.getCookieUID();
            //         this.info.modifyByname = this.appCookies.getCookieTNAME();
            //         // 保存数据
            //         this.apis.postJson(url, this.info)
            //             .then((res) => {
            //                 if (res.code === 1) {
            //                     this.editDrawer = false;
            //                     this.initTable();
            //                     this.$message({
            //                         message: '保存成功',
            //                         showClose: true, offset: 200, type: 'success'
            //                     });
            //                 } else {
            //                     this.$message({
            //                         message: res.msg,
            //                         showClose: true, offset: 50, type: 'error'
            //                     });
            //                 }
            //             }).catch(err => {
            //             console.log(err);
            //         });
            //     }
            // })
            // },
        }
    }
</script>

<style lang="scss" scoped>
</style>
