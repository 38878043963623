<template>
    <el-drawer
        title="信息编辑"
        :visible.sync="enrollEditorDrawer"
        :modal-append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :wrapperClosable="false"
        :ref="orderId"
        direction="rtl"
        custom-class="demo-drawer"
        size="80%"
    >
        <div class="demo-drawer__content input-collapse">
        <el-form ref="editForm" :model="info" :rules="ruleValidate" label-suffix=":" label-width="120px">
            <el-collapse v-model="activeNames">
                <el-collapse-item title="工种信息" name="c1">
                    <template slot="title">
                        <span class="title">工种信息</span>
                    </template>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.categoryId" :gnvqId.sync="info.gnvqId"
                                            :levelId.sync="info.levelId"  @callback="levelChange"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="报名机构" prop="orgId">
                                {{info.orgName}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="班级" prop="classId">
                                <el-select v-model="info.classId" filterable placeholder="请选择班级" @change="classChange">
                                    <el-option value="" label="请选择"></el-option>
                                    <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum+'-'+item.classNum+'[截至时间：'+ item.endDate + ']'" :key="item.classId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="报名金额" prop="priceValue">
                                {{info.priceValue}}元
                            </el-form-item>
                        </el-col>
                    </el-row>
<!--                    <el-row :gutter="32">-->
<!--                        <el-col :span="8">-->
<!--                            <el-form-item label="工种">-->
<!--                                {{info.gnvqName}}-{{info.levelName}}-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="8">-->
<!--                            <el-form-item label="报名机构">-->
<!--                                {{info.orgName}}-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="8">-->
<!--                            <el-form-item label="班级" prop="classId">-->
<!--                                <el-select v-model="info.classId" filterable placeholder="请选择班级">-->
<!--                                    <el-option value="" label="请选择"></el-option>-->
<!--                                    <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum+'-'+item.classNum+'[截至时间：'+ item.endDate + ']'" :key="item.classId"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
                </el-collapse-item>
                <el-collapse-item title="基本信息" name="c2">
                    <template slot="title">
                        <span class="title">基本信息</span>
                    </template>
                    <el-row :gutter="32" v-if="info.classConfig.idcardImg.needInput===1">
                        <el-col :span="24">
                            <el-form-item label="身份证" required >
                                <el-row>
                                    <el-col :span="12" style="text-align: center">
                                        <SingleUploadCroppedImg
                                            @callback="uploadIdCardFront"
                                            :imageUrl="info.idCardFrontUrl"
                                            :width="243"
                                            :height="153"
                                            :text="'上传身份证头像面照片'"
                                            :cropper="true" :autoCropWidth="600" :autoCropHeight="375">
                                        </SingleUploadCroppedImg>
                                    </el-col>
                                    <el-col :span="12" style="text-align: center">
                                        <SingleUploadCroppedImg
                                            @callback="uploadIdCardBack"
                                            :imageUrl="info.idCardBackUrl"
                                            :width="243"
                                            :height="153"
                                            :text="'上传身份证国徽面照片'"
                                            :cropper="true" :autoCropWidth="600" :autoCropHeight="375">
                                        </SingleUploadCroppedImg>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="14">
                            <el-form-item label="姓名" prop="trueName">
                                <el-input v-model="info.trueName" placeholder="请输入姓名" />
                            </el-form-item>
                            <el-form-item label="联系电话">
                                <el-input v-model="info.phone" placeholder="请输入联系电话" />
                            </el-form-item>
                            <el-form-item label="性别" prop="sex" v-if="info.classConfig.sex.needInput===1">
                                <el-radio-group v-model="info.sex">
                                    <el-radio :label="1">男</el-radio>
                                    <el-radio :label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="民族" v-if="info.classConfig.nationName.needInput===1">
                                <el-input v-model="info.nationName" placeholder="请输入民族" />
                            </el-form-item>
                            <el-form-item label="身份证号" prop="idCard" v-if="info.classConfig.idCard.needInput===1">
                                <el-input v-model="info.idCard" placeholder="请输入身份证号" @on-blur="checkHasEnroll" />
                            </el-form-item>
                            <el-form-item label="家庭住址" prop="homeAddr" v-if="info.classConfig.homeAddr.needInput===1">
                                <el-input v-model="info.homeAddr" type="textarea" placeholder="请输入家庭住址" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="text-align: center" v-if="info.classConfig.certHeadImg.needInput===1">
                            <SingleUploadImg @callback="uploadCertHeadImg" :imageUrl="info.certHeadImgUrl"
                                             :width="114" :height="145" :text="info.headImgBgcolor +'照片（大小94×132px、20kb）'"></SingleUploadImg>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-if="info.classConfig.education.needInput===1 || info.classConfig.eduImg.needInput===1">
                        <el-col :span="6"  v-if="info.classConfig.education.needInput===1">
                            <el-form-item label="学历" prop="educationId">
                                <el-select v-model="info.educationId" filterable placeholder="请选择学历">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in eduList" :value="item.dictId" :label="item.dictName" :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18"  v-if="info.classConfig.eduImg.needInput===1">
                            <el-form-item label="毕业证书" required>
                                <MutiUploadImg ref="mutiUploadImg" :imageList.sync="info.eduImgList"></MutiUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-if="info.classConfig.workYears.needInput===1">
                        <el-col :span="6">
                            <el-form-item label="工龄" prop="workYears">
                                <el-input v-model="info.workYears">
                                    <span slot="append">年</span>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="工龄证明" required>
                                <MutiUploadImg ref="mutiUploadImg2" :imageList.sync="info.workYearsImgList"></MutiUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="32"  v-if="info.classConfig.certName.needInput===1">
                        <el-col :span="24">
                            <el-form-item label="证书">
                                <div v-for="(item, index) in info.certList" :key="index" style="display: flex; flex-direction: row; align-items: flex-start; margin-bottom: 5px">
                                    <el-form-item>
                                        <el-select filterable v-model="item.certName" placeholder="请选择证书类型">
                                            <el-option value="">请选择学历</el-option>
                                            <el-option v-for="item in certNameList" :value="item.dictName" :label="item.dictName"  :key="item.dictName"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-button type="success" plain round @click="handleRemove(index)">删除</el-button>
                                    <el-form-item label="证书照片">
                                        <MutiUploadImg ref="mutiUploadImg3" :imageList.sync="item.certFiles"></MutiUploadImg>
                                    </el-form-item>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="success" plain round @click="handleAdd">继续添加证书信息</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-if="info.classConfig.signImg.needInput===1">
                        <el-col :span="24">
                            <el-form-item label="本人签名">
                                <SingleUploadImg @callback="uploadSignImg" :imageUrl="info.signImgUrl"
                                                 :width="160" :height="120" :text="info.headImgBgcolor +'照片（大小94×132px、20kb）'"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="其他信息" name="c21" v-if="info.defFieldList.length > 0">
                    <template slot="title">
                        <span class="title">其他信息</span>
                    </template>
                    <el-row :gutter="16">
                        <el-col :span="12" v-for="(item, idx) in info.defFieldList" :key="'defF' + idx">
                            <template v-if="item.controlType==='INPUT'">
                                <el-form-item :label="item.fieldName">
                                    <el-input v-model="item.fieldValue" placeholder="请输入" />
                                </el-form-item>
                            </template>
                            <template v-if="item.controlType==='CHECKBOX'">
                                <el-form-item :label="item.fieldName">
                                    <el-checkbox-group v-model="item.fieldValues">
                                        <el-checkbox v-for="option in item.controlOptions" :key="option.optionValue"
                                                     :label="option.optionValue">{{option.optionLable}}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </template>
                            <template v-if="item.controlType==='RADIO'">
                                <el-form-item :label="item.fieldName">
                                    <el-radio-group v-model="item.fieldValue">
                                        <el-radio v-for="option in item.controlOptions" :key="option.optionValue"
                                                  :label="option.optionValue">{{option.optionLable}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </template>
                            <template v-if="item.controlType==='SELECT'">
                                <el-form-item :label="item.fieldName">
                                    <el-select filterable v-model="item.fieldValue" placeholder="请选择">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="option in item.controlOptions"
                                                   :value="option.optionValue"
                                                   :label="option.optionLable"  :key="option.optionValue"></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                            <template v-if="item.controlType==='TEXTAREA'">
                                <el-form-item :label="item.fieldName">
                                    <el-input v-model="item.fieldValue" placeholder="请输入" />
                                </el-form-item>
                            </template>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="12" v-for="(item, idx) in info.defFileList" :key="'defFile' + idx">
                            <el-form-item :label="item.fieldName">
                                <SingleUploadImg @callback="uploadDefImg" :imageUrl="item.fileValueUrl" :index="idx"
                                                 :width="160" :height="120" ></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="银行卡信息" name="c3" v-if="info.classConfig.bankNo.needInput===1">
                    <template slot="title">
                        <span class="title">银行卡信息</span>
                    </template>
                    <el-row :gutter="32" >
                        <el-col :span="6">
                            <el-form-item label="账户姓名">
                                {{ info.trueName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="开户行名称">
                                <el-input v-model="info.bankName" placeholder="请输入开户行名称" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="银行卡号">
                                <el-input v-model="info.bankNo" placeholder="请输入银行卡号" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="证书邮寄信息" name="c4" v-if="info.classConfig.certPost.needInput===1">
                    <template slot="title">
                        <span class="title">证书邮寄信息</span>
                    </template>
                    <el-row :gutter="32">
                        <el-col :span="6">
                            <el-form-item label="收件人姓名" prop="addrUname">
                                <el-input v-model="info.addrUname" placeholder="请输入收件人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系电话" prop="addrPhone">
                                <el-input v-model="info.addrPhone" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="14">
                            <el-form-item label="邮寄地址" prop="addrProvince">
                                <AreaSelect ref="areaSelect" :provinceId.sync="info.addrProvince" :cityId.sync="info.addrCity" :districtId.sync="info.addrDistrict"></AreaSelect>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item label="详细地址" prop="addrDesc">
                                <el-input v-model="info.addrDesc" placeholder="请输入详细地址">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="教材邮寄信息" name="c5" v-if="info.classConfig.bookPost.needInput===1">
                    <template slot="title">
                        <span class="title">教材邮寄信息</span>
                    </template>
                    <el-row :gutter="32">
                        <el-col :span="6">
                            <el-form-item label="收件人姓名" prop="addrUname">
                                <el-input v-model="info.book.linkName" placeholder="请输入收件人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系电话" prop="addrPhone">
                                <el-input v-model="info.book.linkMobile" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="14">
                            <el-form-item label="邮寄地址" prop="addrProvince">
                                <AreaSelect :provinceId.sync="info.book.provinceId" :cityId.sync="info.book.cityId" :districtId.sync="info.book.districtId"></AreaSelect>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item label="详细地址" prop="addrDesc">
                                <el-input v-model="info.book.addressDesc" placeholder="请输入详细地址">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="报名费用状态" name="c6">
                    <template slot="title">
                        <span class="title">报名费用状态</span>
                    </template>
                    <el-row :gutter="16">
                        <el-col :span="4">
                            <el-form-item label="报名金额">
                                {{info.priceValue}}元
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="支付金额">
                                {{info.payValue}}元
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="支付方式">
                                {{info.payWay === 1 ? '微信支付' : info.payWay === 2 ? '支付宝' : '线下缴费'}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="支付状态">
                                <el-tag :type="info.payStatus | colorFilter2">
                                    {{ info.payStatus  | payStatusFilter}}
                                </el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="审核状态">
                                <el-tag :type="info.confirmStatus | colorFilter2">
                                    {{ info.confirmStatus  | confirmStatusFilter}}
                                </el-tag>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
            </el-collapse>
        </el-form>
        <!-- <Loading v-show="showSpin" :spinText="spinText"></Loading> -->
        <!--        <ImgCropperDiag :show="showImgCropper" @callback="hideImgCropper"></ImgCropperDiag>-->
        </div>
        <div class="demo-drawer__footer">
            <el-button style="margin-right: 8px" @click="enrollEditorDrawer=false">关闭</el-button>
            <el-button type="primary" @click="tosave('editForm')">确认保存</el-button>
        </div>
    </el-drawer>
</template>

<script>
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    import MutiUploadImg from "@c/upload/MutiUploadImg";
    import AreaSelect from "@v/components/AreaSelect";
    import SingleUploadCroppedImg from '@c/upload/SingleUploadCroppedImg';
    import GnvqSelect from "@v/components/GnvqSelect";
    //import Loading from '../../../components/common/Loading';
    // import ImgCropperDiag from '../../components/common/ImgCropperDiag';
    export default {
        components: {
            SingleUploadImg,
            MutiUploadImg,
            AreaSelect,
            SingleUploadCroppedImg,
            GnvqSelect
            //Loading,
            // ImgCropperDiag
        },
        filters: {
            dataStatusFilter(status) {
                const statusMap = {
                    1: '已提交',
                    0: '暂存',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            confirmStatusFilter(status) {
                const statusMap = {
                    1: '已审核',
                    0: '未审核',
                };
                return statusMap[status]
            },
            payStatusFilter(status) {
                const statusMap = {
                    1: '已支付',
                    0: '未支付',
                };
                return statusMap[status]
            },
            payWayFilter(status) {
                const statusMap = {
                    1: '微信支付',
                    2: '支付宝',
                    3: '线下支付'
                };
                return statusMap[status]
            },
            enrollTypeFilter(status) {
                const statusMap = {
                    'SELF': '个人',
                    'COMPANY': '公司',
                };
                return statusMap[status]
            },
            classTypeFilter(status) {
                const statusMap = {
                    'LEARN': '继续教育',
                    'ENROLL': '考试报名',
                };
                return statusMap[status]
            },
            postBookFilter(status) {
                const statusMap = {
                    1: '是',
                    2: '否'
                };
                return statusMap[status]
            },
            sexFilter(status) {
                const statusMap = {
                    1: '男',
                    2: '女'
                };
                return statusMap[status]
            },
            taskTypeFilter(status) {
                const statusMap = {
                    '1000': '开放任务',
                    '2000': '内部任务'
                };
                return statusMap[status]
            },
            consumTypeFilter(status) {
                const statusMap = {
                    1: '单人领取',
                    2: '多人领取'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
            colorFilter2(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success',
                };
                return statusMap[status]
            },
        },
        data () {
            const validateNumber = (rule, value, callback) => {
                if (!Number.isInteger(+value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };
            const validateSelect = (rule, value, callback) => {
                // alert(rule.message + value)
                if (value === undefined || value === null || value === '') {
                    callback(new Error(rule.message));
                } else {
                    callback();
                }
            };
            const validatePhone = (rule, value, callback) => {

                let phone_reg = new RegExp(/^\d{3}-?\d{7,8}|\d{4}-?\d{7,8}$/);
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入联系电话'));
                } else if (!phone_reg.test(value) && !(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                    callback(new Error('联系电话格式不对'));
                } else {
                    callback();
                }
            };
            const validateIdcard = (rule, value, callback) => {
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入身份证号'));
                } else if (value.length !== 18) {
                    callback(new Error('身份证号格式不对'));
                } else {
                    callback();
                }
            };

            return {
                activeNames: ["c1", "c2", "c21", "c3", "c4", "c5", "c6"],
                enrollEditorDrawer: false,
                showImgCropper: false,
                currentStep: 0,
                info: {
                    orderId: '',
                    userId: this.appCookies.getCookieUID(),
                    provinceId: '',
                    cityId: '',
                    districtId: '',
                    categoryId: '',
                    gnvqId: '',
                    levelId: '',
                    orgId: '',
                    classId: '',
                    trueName: '',
                    sex: '',
                    idCard: '',
                    homeAddr: '',
                    idCardFront: '',
                    idCardBack: '',
                    certHeadImg: '',
                    idCardFrontUrl: '/images/common/idcard-front.png',
                    idCardBackUrl: '/images/common/idcard-back.png',
                    certHeadImgUrl: '/images/my/cance-bg-02.png',
                    educationId: '',
                    educationName: '',
                    eduImgList: [],
                    workYears: '',
                    headImgBgcolor: '蓝底',
                    workYearsImgList: [],
                    certList: [{
                        certName: '',
                        certFiles: []
                    }],
                    phone: '',
                    payStatus: 0,
                    defFieldList: [],
                    defFileList: [],
                    agree: false,
                    classConfig: {
                        workYears: {
                            needInput: 1
                        },
                        nationName: {
                            needInput: 1
                        },
                        education: {
                            needInput: 1
                        },
                        idCard: {
                            needInput: 1
                        },
                        sex: {
                            needInput: 1
                        },
                        homeAddr: {
                            needInput: 1
                        },
                        trueName: {
                            needInput: 1
                        },
                        bookPost: {
                            needInput: 0
                        },
                        certHeadImg: {
                            needInput: 1
                        },
                        idcardImg: {
                            needInput: 1
                        },
                        phone: {
                            needInput: 1
                        },
                        certName: {
                            needInput: 0
                        },
                        bankNo: {
                            needInput: 0
                        },
                        eduImg: {
                            needInput: 0
                        },
                        signImg: {
                            needInput: 0
                        },
                        certPost: {
                            needInput: 0
                        }
                    },
                    book: {
                        provinceId: '',
                        cityId: '',
                        districtId: '',
                        addressDesc: '',
                    },
                },
                orgPayWay: '',
                orgList: [],
                classList: [],
                eduList: [],
                certNameList: [],
                infoModal: false,
                modelInfo: {
                    conditionTitle: '报考条件',
                    conditionDesc: ''
                },
                ruleValidate: {
                    orgId: [
                        { required: true, validator: validateSelect, message: '请选择报名机构', trigger: 'change' }
                    ],
                    classId: [
                        { required: true, validator: validateSelect, message: '请选择班级', trigger: 'change' }
                    ],
                    // 报名信息
                    trueName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    sex: [
                        { required: true, type: 'number', message: '请选择性别', trigger: 'change' }
                    ],
                    idCard: [
                        { required: true, validator: validateIdcard, trigger: 'blur' }
                    ],
                    homeAddr: [
                        { required: true, message: '请输入家庭地址', trigger: 'blur' }
                    ],
                    educationId: [
                        { required: true, type: 'number', message: '请选择学历', trigger: 'change' }
                    ],
                    workYears: [
                        { required: true, validator: validateNumber, message: '请输入工龄', trigger: 'blur' }
                    ],
                    // addrProvince: [
                    //     { required: true, validator: validateSelect, message: '请选择省份', trigger: 'change' }
                    // ],
                    // addrCity: [
                    //     { required: true, validator: validateSelect, message: '请选择城市', trigger: 'change' }
                    // ],
                    // addrDistrict: [
                    //     { required: true, validator: validateSelect, message: '请选择区/县', trigger: 'change' }
                    // ],
                    // addrDesc: [
                    //     { required: true, message: '请输入详细地址', trigger: 'blur' }
                    // ],
                    phone: [
                        { required: true, validator: validatePhone, trigger: 'blur' }
                    ],
                    selfFlag: [
                        { required: true, type: 'number', message: '请选择是否本人报名', trigger: 'change' }
                    ],
                    payWay: [
                        { required: true, type: 'number', message: '请选择支付方式', trigger: 'change' }
                    ],
                },
                qrCodeImg: '',
                showSpin: false,
                spinText: '请稍等，正在处理',
                setIn: ''
            }
        },
        async mounted() {
            let orderId = this.$route.query.orderId;
            if (orderId !== undefined && orderId !== null && orderId !== '') {
                this.info.orderId = orderId;
                await this.getOrder();
            }
            this.getEduList();
            this.getCertNameList();
            window.scroll(0, 0);
        },
        methods: {
            levelChange: function (levelInfo) {
                console.log(levelInfo)
                this.getClassList()
            },
            classChange: function (classId) {
                // this.info.classId = classId
                for (let item of this.classList) {
                    if (item.classId === classId) {
                        this.info.priceValue = item.amount;
                    }
                }
                this.getClassConfig(classId)
            },
            getClassConfig: function (classId) {
                this.apis.get('course/class/getClassConfig?classId=' + classId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info.classConfig = res.data.configMap || {};
                            this.info.defFieldList = res.data.defFieldList || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            show: function (orderId) {
                this.enrollEditorDrawer = true;
                this.orderId = orderId;
                this.getById();
                this.$nextTick(function () {
                });
            },
            //获取学历下拉菜单列表
            getEduList: function () {
                this.apis.get('system/dict/getList?typeCode=0003')
                    .then((res) => {
                        if (res.code === 1) {
                            this.eduList = res.data || []
                            // this.eduList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //获取证书类型下拉菜单列表
            getCertNameList: function () {
                this.apis.get('system/dict/getList?typeCode=0005')
                    .then((res) => {
                        if (res.code === 1) {
                            this.certNameList = res.data || []
                            // this.certNameList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //上传身份证正面SingleUploadCroppedImg组件的callback方法
            uploadIdCardFront: function (webPath, savePath) {
                this.info.idCardFront = savePath;
                this.info.idCardFrontUrl = webPath;
                console.log("uploadIdCardFront");
                console.log(this.info.idCardFront);
                let data = {
                    fileUrl: webPath,
                    side: 'front'
                }
                this.spinText = '正在进行身份证识别，请稍等';
                this.apis.postForm('third/ai/ocr/idcardUrl', data)
                    .then((res) => {
                        if (res.code === 1) {
                            let card = res.data;
                            this.info.trueName = card.trueName;
                            this.info.sex = card.sex;
                            this.info.idCard = card.idCard;
                            this.info.homeAddr = card.homeAddr;
                            this.info.birthDay = card.birthDay;
                            this.info.nation = card.nation;
                            this.$forceUpdate();
                            this.checkHasEnroll();
                            console.log(res.data);
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //上传身份证背面SingleUploadCroppedImg组件的callback方法
            uploadIdCardBack: function (webPath, savePath) {
                this.info.idCardBack = savePath;
                this.info.idCardBackUrl = webPath;
            },
            //上传蓝底照片SingleUploadImg组件的callback方法
            uploadCertHeadImg: function (webPath, savePath) {
                this.info.certHeadImg = savePath;
                this.info.certHeadImgUrl = webPath;
            },
            uploadSignImg: function (webPath, savePath) {
                this.info.signImg = savePath;
                this.info.signImgUrl = webPath;
            },
            uploadDefImg: function (webPath, savePath, idx) {
                this.info.defFileList[idx].fileValue = savePath;
                this.info.defFileList[idx].fileValueUrl = webPath;
            },
            //添加证书照片表单域
            handleAdd: function () {
                this.info.certList.push({
                    certName: '',
                    certFiles: []
                });
            },
            //保存
            tosave: function (formRef) {
                // console.log(this.info)
                // 检查文件是否都已经上传

                if (this.info.classConfig.idcardImg.needInput===1) {
                    if (this.info.idCardFrontUrl === undefined || this.info.idCardFrontUrl === '' || this.info.idCardFrontUrl === null) {
                        this.$message({
                            message: '请上传身份证正面照片',
                            showClose: true, offset: 200, type: 'success'
                        });
                        return;
                    }
                    if (this.info.idCardBackUrl === undefined || this.info.idCardBackUrl === '' || this.info.idCardBackUrl === null) {
                        this.$message({
                            message: '请上传身份证反面照片',
                            showClose: true, offset: 200, type: 'success'
                        });
                        return;
                    }
                }
                if (this.info.classConfig.certHeadImg.needInput===1) {
                    if (this.info.certHeadImgUrl === undefined || this.info.certHeadImgUrl === '' || this.info.certHeadImgUrl === null) {
                        this.$message({
                            message: '请上传蓝底照片',
                            showClose: true, offset: 200, type: 'success'
                        });
                        return;
                    }
                }
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        // 保存数据
                        this.apis.postJson('user/enrollOrder/save', this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    if (this.info.orderId === undefined || this.info.orderId === '') {
                                        this.info.orderId = res.data;
                                    }
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.close()
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            getById: async function () {
                await this.apis.get('user/enrollOrder/getById?orderId=' + this.orderId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data
                            this.initData();
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
            },
            initData: function () {
                this.apis.get('course/class/getList?companyId=' + this.info.companyId + '&gnvqId=' + this.info.gnvqId + '&levelId=' + this.info.levelId + '&operUserId=' + this.appCookies.getCookieUID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            setOrgId: function (val) {
                for (let item of this.orgList) {
                    if (item.orgId === val) {
                        this.orgPayWay = item.payWay;
                    }
                }
                if (this.orgPayWay === 3) {
                    this.info.payWay = 3;
                }
                this.getClassList()
            },
            getClassList: function () {
                this.info.classId = '';
                this.classList = [];
                if (this.info.companyId === undefined || this.info.companyId === null || this.info.companyId === '') {
                    return;
                }
                if (this.info.gnvqId === undefined || this.info.gnvqId === null || this.info.gnvqId === '') {
                    return;
                }
                if (this.info.levelId === undefined || this.info.levelId === null || this.info.levelId === '') {
                    return;
                }
                this.apis.get('course/class/getList?companyId=' + this.info.companyId + '&gnvqId=' + this.info.gnvqId + '&levelId=' + this.info.levelId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            handleRemove: function (index) {
                this.info.certList.splice(index, 1);
            },
            hideImgCropper: function () {
                this.showImgCropper = false;
            },
            checkHasEnroll: function () {
                let data = {
                    gnvqId: this.info.gnvqId,
                    levelId: this.info.levelId,
                    idCard: this.info.idCard,
                    orderId: this.info.orderId,
                }
                this.apis.get('user/enrollOrder/checkHasEnrollByIdCard', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$confirm('该身份证号已经报名过此工种等级,不能重复报名！', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                            }).catch(() => {
                            });
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            close: function () {
                this.$emit('callback', this.orderId)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .editorhome {
        background-color: white;
    }
    .form-title {
        margin: 15px 10px;
        h3 {
            border-left: 3px solid #0098cd;
            padding-left: 8px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
        }
    }
    .h2 {
        border-left: 3px solid #0098cd;
        overflow: hidden;
        text-indent: 6px;
        font-size: 18px;
        color: #6f6f6f;
    }
    .demo-drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px 26px 16px;
        text-align: right;
        background: #fff;
    }
</style>
