import { render, staticRenderFns } from "./FaceBox.vue?vue&type=template&id=382d6b03&scoped=true&"
import script from "./FaceBox.vue?vue&type=script&lang=js&"
export * from "./FaceBox.vue?vue&type=script&lang=js&"
import style0 from "./FaceBox.vue?vue&type=style&index=0&id=382d6b03&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "382d6b03",
  null
  
)

export default component.exports